import * as React from 'react';
import {Request} from '../requests/request';

export const RenderCategory = (props) => {
    const {item, index, onEdit, token, onDeleteSuccess} = props;

    return(
        <div
            key={index.toString()}
            className={styles.main(index)}
        >
            <div className='flex flex-col flex-1' onClick={() => {onEdit();}}>
                <p><label className={styles.label}>Название:</label> <label className={styles.name}>{item.name}</label></p>
                <p><label className={styles.label}>Статус активности:</label> <label className={styles.visible(item.visible)}>{item.visible? 'Активно': "Не активно"}</label></p>
            </div>
            <button
                className={styles.btnDelete}
                onClick={() => {
                    try {
                        let response = Request(`/delete/category`, 'delete', token, JSON.stringify({
                            id: item._id
                        }));
                        response.then((json) => {
                            if(json.status === 200 && json.data.status === 'success') {
                                onDeleteSuccess();
                            }
                        })
                    } catch(e) {
                        console.log('e', e);
                    };
                }}
            >Удалить</button>
        </div>
    );
};

const styles = {
    main: (index) => `mb-[20px] bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] flex flex-row items-center justify-between ${index === 0? 'mt-[20px]': ''}`,
    name: 'text-[16px] font-medium',
    visible: (visible) => `text-[16px] font-medium ${visible? 'text-main ': "text-red "}`,
    btnDelete: ' py-[10px] px-[20px] text-red font-medium text-[16px]',
    label: 'font-regular'
}
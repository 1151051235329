import * as React from 'react';
import { GetData } from '../../requests/get';
import { useSelector, useDispatch } from 'react-redux';
import { RenderUser } from '../../components/RenderUser';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { pageCount } from '../../configs/constant';
import { ModalAddUser } from '../../components/modals/ModalAddUser';
import { Request } from '../../requests/request';
import { setCategoriesRedux } from '../../redux/categories/categories';
import { setSubCategoriesRedux } from '../../redux/categories/subcategories';
import { setUsersRedux } from '../../redux/users/users';
import { CustomSelect2 } from '../../components/CustomSelect';
import { getNameOrNameOrg } from '../../configs/functions';
import { Loader } from '../../components/Loader';

const sortData = [
    { value: 'Тип аккаунта', label: 'Тип аккаунта' },
    { value: 'ФИО/Организация', label: 'ФИО/Организация' },
    { value: 'Номер телефона', label: 'Номер телефона' }
]

export const Users = () => {
    const usersSlice = useSelector(state => state.users.users);
    const [data, setData] = React.useState(usersSlice);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);
    const token = useSelector(state => state.auth.session.token);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [sort, setSort] = React.useState('Сортировка');
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [modalAdd, setModalAdd] = React.useState(false);

    console.log('token', token);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            try {
                const responseUser = Request('/api/users', 'get', token, null);
                responseUser.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                        dispatch(setUsersRedux(json.data.data));
                    };
                    console.log('resp ', json)
                    setLoading(false);
                }).catch((e) => { console.log('e users catch', e); })
                const response = Request(`/get/categories`, 'get', token, null);
                response.then((json) => {
                    //console.log('json categories', data, statusCode);
                    if(json.status === 200) {
                        dispatch(setCategoriesRedux(json.data.data))
                    };
                }).catch((e) => { console.log('e categories catch', e); })
                const responseSubcategories = Request('/get/subcategories', 'get', token, null);
                responseSubcategories.then((json) => {
                    if(json.status === 200 ) {
                        dispatch(setSubCategoriesRedux(json.data.data));
                    }
                }).catch((e) => { console.log('e subcategories catch', e); })
            } catch(e) {
                console.log('e', e);
            }
            
        }
        return () => refRepeat.current = true;
    }, []);

    React.useEffect(() => {
        if(search.length > 0) {
            setLoading(true);
            console.log('search', search);
            try {
                const response = Request(`/api/users/search/admin?search=${search}`, 'get', token, null);
                response.then((json) => {
                    console.log('json searc', json);
                    if(json.status === 200) {
                        setData(json.data.data);
                        dispatch(setUsersRedux(json.data.data));
                    }
                    setLoading(false)
                }).catch((e) => {
                    console.log('e', e);
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            }
            
        } else {
            try {
                setLoading(true);
                const responseUser = Request('/api/users', 'get', token, null);
                responseUser.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                        dispatch(setUsersRedux(json.data.data));
                    };
                    setLoading(false);
                })
            } catch(e) {
                console.log('e search', e);
                setLoading(false);
            };
        };
    }, [search.length]);

    return(
        <div className={styles.main}>
            <p className={styles.title}><label className={styles.label}>Пользователи</label>: Найдено {data.length}</p>
            <div className={styles.div1}>
                <div className='flex flex-row w-full'>
                    <CustomSelect2 
                        data={sortData} 
                        value={sort} 
                        onChange={(value) => { setSort(value.value); }} 
                        className1="w-[250px] mr-[20px]"
                    />
                    <input 
                        className={'w-1/3 ' + styles.search}
                        value={search}
                        onChange={(event) => { setSearch(event.target.value); }}
                        placeholder="Поиск"
                    />
                </div>
                
                <button
                    className={styles.btnAdd}
                    onClick={() => { setModalAdd(true); }}
                >Добавить</button>
            </div>
            <button
                className='py-[5px] px-[20px] rounded-[12px] bg-main text-[18px] font-medium text-white tablet:flex mobile:flex laptop:hidden desktop:hidden mt-[20px] items-center justify-center'
                onClick={() => { setModalAdd(true); }}
            >Добавить</button>
            <div className={styles.divList} >
            {
                loading?
                    <Loader />:
                    //sortUserData(data.filter((item, index) => index >= (page - 1) * pageCount && index < page * pageCount ), sort)
                    data.map((item, index) => 
                        <RenderUser 
                            key={index.toString()}
                            item={item}
                            index={index}
                            navigate={navigate}
                        />
                    )
            }
            </div>
            {/* <div className='mt-[20px] mx-[20px] flex flex-row justify-end mb-[10px]'>
                <Pagination 
                    page={page}
                    setPage={setPage}
                    length={data.length}
                />
            </div> */}
            {
                modalAdd && 
                <ModalAddUser 
                    setModalVisible={setModalAdd}
                    token={token}
                    onSuccess={() => {
                        setLoading(true);
                        GetData(`/api/users`, token, (json, statusCode) => {
                            if(statusCode === 200) {
                                setData(json.data);
                                dispatch(setUsersRedux(json.data));
                                setLoading(false);
                                setModalAdd(false);
                            } else {
                                setLoading(false);
                            }
                        }, (e) => {
                            console.log('e', e);
                            setLoading(false);
                        });
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: 'flex flex-1 flex-col w-full h-full overflow-y-scroll',
    title: 'mt-[10px] mx-[20px] text-[20px] font-medium',
    label: 'text-main',
    div1: 'flex flex-row justify-between h-[41px] mx-[20px] mt-[10px]',
    divList: 'grid grid-cols-1 gap-[15px] mt-[20px]',
    btnSort: 'py-[5px] px-[20px] bg-main rounded-[12px] text-white font-medium text-[18px] mr-[10px]',
    search: 'border-main border-[1px] rounded-[12px] px-[20px] py-[10px] text-[16px] text-black font-regular outline-none',
    btnAdd: 'py-[5px] px-[20px] rounded-[12px] w-[150px] bg-main text-[18px] font-medium text-white flex items-center justify-center'
};

const sortUserData = (data, sort) => {
    switch(sort) {
        case 'Тип аккаунта': {
            let result = data.sort(function(a, b) {
                if(a.typeUser.length > b.typeUser.length) {
                    return 1
                } else {
                    return -1;
                };
            })
            return result; 
        }
        case 'ФИО/Организация': 
            return data.sort(function(a, b) {
                let aResult = '';
                let bResult = '';
                if(getNameOrNameOrg(a).nameOrg !== '') {
                    aResult += getNameOrNameOrg(a).nameOrg;
                } else {
                    aResult += getNameOrNameOrg(a).name + ' ' + getNameOrNameOrg(a).surname;
                };
                if(getNameOrNameOrg(b).nameOrg !== '') {
                    bResult += getNameOrNameOrg(b).nameOrg;
                } else {
                    bResult += getNameOrNameOrg(b).name + ' ' + getNameOrNameOrg(b).surname;
                }
                if(aResult > bResult) {
                     return 1;
                } else {
                    return -1;
                };
            });
        case 'Номер телефона': 
            return data.sort(function(a, b) {
                if(a.phone > b.phone) {
                    return 1;
                } else {
                    return -1;
                };
            });
        default: return data;
    };
}
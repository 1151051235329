import * as React from 'react';
import data from '../../cities.json';
import { useDispatch } from 'react-redux';
import { setCityUserSlice, setGeolocationUserSlice } from '../../redux/users/users';

export const ModalCity = (props) => {
    const { setModalVisible } = props;
    const [city, setCity] = React.useState('');
    const dispatch = useDispatch();

    React.useEffect(() => {
        
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Выбор города</p>
                <p className={styles.searchLabel}>Поиск</p>
                <div className='flex flex-row items-center justify-left mt-[10px]'>
                    <input 
                        className={styles.input}
                        value={city}
                        onChange={(event) => { setCity(event.target.value); }}
                    />
                </div>
                <div className='bg-grey w-full h-[200px] mt-[10px] overflow-y-scroll'>
                {
                    data.filter((item, index) => (item.name.toLocaleLowerCase().includes(city) && city !== '') || (city === '')).map((item, index) => 
                        <div
                            key={index.toString()}
                            className='h-[41px] mb-[10px] px-[20px] flex flex-row items-center font-regular'    
                            onClick={() => {
                                dispatch(setCityUserSlice(item.name));
                                dispatch(setGeolocationUserSlice({
                                    latitude: Number(item.coords.lat),
                                    longitude: Number(item.coords.lon)
                                }))
                                setModalVisible(false);
                            }}
                        >{item.name}</div>
                    )
                }
                </div>
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { setModalVisible(false); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnApply}
                        onClick={() => { setModalVisible(false); }}
                    >Применить</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'w-full h-full flex flex-col items-center justify-center absolute top-0 left-0',
    div: 'bg-white w-2/4 shadow-[0_2px_8px_0.2px_rgba(0,0,0,0.3)] rounded-[10px] p-[20px]',
    title: 'text-[20px] font-medium ',
    searchLabel: 'mt-[10px] text-grey-text',
    input: 'border-main border-[1px] h-[41px] rounded-[10px] w-1/2 outline-main px-[20px]',
    divBtns: 'flex flex-row mt-[10px]',
    btnClose: 'w-1/4 h-[41px] bg-grey rounded-[10px] font-medium',
    btnApply: 'w-1/4 h-[41px] bg-main ml-[10px] rounded-[10px] text-white font-medium'
}
import {combineReducers} from 'redux';

import auth from './auth';
import categories from './categories/categories';
import subcategories from './categories/subcategories';
import users from './users/users';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        categories,
        subcategories,
        users,
        ...asyncReducers
    })

    return combinedReducer(state, action);
};

export default rootReducer;
import * as React from 'react';
import { Request } from '../requests/request';

export const RenderSubCategory = (props) => {
    const {item, index, categories, token, onSuccess, onEdit} = props;

    console.log('categories', categories);

    return(
        <div className={styles.main(index)}>
            <div 
                className={styles.div}
                onClick={() => {
                    onEdit();
                }}
            >
                <p><label className={styles.label}>Название:</label> <label className={styles.name}>{item.name}</label></p>
                <p><label className={styles.label}>Статус активности:</label> <label className={styles.visible(item.visible)}>{item.visible? 'Активно': 'Не активно'}</label></p>
                <p><label className={styles.label}>Категория:</label> <label className={styles.parent}>{getParent(categories, item.parent)}</label></p>
            </div>
            <button
                className={styles.btnDelete}
                onClick={() => {
                    try {
                        let response = Request(`/delete/subcategory`, 'delete', token, JSON.stringify({
                            id: item._id
                        }));
                        response.then((json) => {
                            if(json.status === 200 && json.data.status === 'success') {
                                onSuccess();
                            } 
                        })
                    } catch(e) {
                        console.log('e', e);
                    }
                }}
            >Удалить</button>
        </div>
    );
};

const styles = {
    main: (index) => `mb-[20px] p-[20px] bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] rounded-[12px] flex flex-row items-center justify-between ${index === 0? 'mt-[20px]': ""}`,
    div: 'felx flex-col w-full',
    name: 'text-[16px] font-medium text-black',
    visible: (visible) =>  `text-[16px] font-medium ${visible? 'text-main ': 'text-red '}`,
    parent: 'font-medium',
    btnDelete: 'px-[20px] text-red py-[10px] font-medium text-[16px]',
    label: 'text-regular text-[16px]'
};

const getParent = (data, id) => {
    let result = data.filter((item, index) => item._id === id);

    if(result.length > 0) {
        return result[0]?.name;
    } else {
        return 'Категория не найдена'
    }
}
import * as React from 'react';
import { RenderComplaints } from '../../components/RenderComplaints';
import { Request } from '../../requests/request';
import { useSelector } from 'react-redux';
import { ModalViewComplaint } from '../../components/modals/ModalViewComplaint';
import { Loader } from '../../components/Loader';

export const Complaints = () => {
    const token = useSelector(state => state.auth.session.token);
    const role = useSelector(state => state.auth.user.role);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    const [modalView, setModalView] = React.useState(false);
    const [modalViewIndex, setModalViewIndex] = React.useState(null);

    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        if(search.length === 0) {
            // if(refRepeat.current === false) {
                setLoading(true);
                try {
                    let response = Request('/api/complaints', 'get', token, null);
    
                    response.then((json) => {
                        if(json.status === 200 && json.data.status === 'success') {
                            setData(json.data.data);
                        }
                        setLoading(false);
                    })
                } catch(e) {
                    console.log('e', e);
                    setLoading(false);
                };
            // };
        } else {
            setLoading(true);
            try {
                let response = Request(`/api/complaints/search?search=${search}`, 'get', token, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    };
                    setLoading(false);
                });
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            }
        }
        
        return () => refRepeat.current = true;
    }, [search]);

    return(
        <div className={styles.main}>
            <p className={styles.title}>Жалобы: найдено {data.length}</p>
            <input 
                value={search}
                onChange={(event) => {
                    setSearch(event.target.value);
                    refRepeat.current = false;
                }}
                placeholder="Поиск"
                className='border-main border-[1px] rounded-[12px] py-[10px] px-[20px] text-[16px] font-regular text-black outline-main mt-[10px] w-1/3'
            />
            <div className='grid grid-cols-1 laptop:grid-cols-2'>
            {
                loading?
                    <Loader />:
                    data.sort(function(a, b) {
                        if(a.visible === true) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }).map((item, index) => 
                        <RenderComplaints 
                            item={item}
                            index={index}
                            token={token}
                            key={index.toString()}
                            role={role}
                            onView={() => {
                                setModalViewIndex(index);
                                setModalView(true);
                            }}
                            onSuccess={() => {
                                try {
                                    let response = Request('/api/complaints', 'get', token, null);
                    
                                    response.then((json) => {
                                        if(json.status === 200 && json.data.status === 'success') {
                                            setData(json.data.data);
                                        }
                                        setLoading(false);
                                    })
                                } catch(e) {
                                    console.log('e', e);
                                    setLoading(false);
                                };  
                            }}
                        />
                    )
            }
            </div>
            {
                modalView && 
                <ModalViewComplaint 
                    item={data[modalViewIndex]}
                    close={() => { setModalView(false); }}
                />
            }
        </div>
    );
};

const styles = {
    main: 'container px-[20px] h-full overflow-y-scroll',
    title: 'text-[20px] font-medium mt-[10px]'
}
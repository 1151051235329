import * as React from 'react';
import Logo from '../assets/icons/logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { getRole } from '../configs/functions';
import { defaultUser } from '../redux/auth/userSlice';
import { onSignOutSuccess } from '../redux/auth/sessionSlice';

export const Header = () => {
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if(window.location.pathname.includes('login')) {
        return(<></>);
    } else {
        return(
            <div className={styles.main}>
                <div className={styles.div1}>
                    <div className={styles.div1Sub}>
                        <div className={styles.divLogo}>
                            <img className={styles.logo} src={Logo} />
                        </div>
                        <p className={styles.name}>{user?.name.length > 0 && user?.surname.length > 0?  user?.name + ' ' + user?.surname: 'Имя не заполнено'}</p>
                    </div>
                    <div className={styles.divRight}>
                        <p className={styles.role}>{getRole(user.role)}:</p>
                        <button 
                            className={styles.profile}
                            onClick={() => { navigate('/profile') }}    
                        >Профиль</button>
                        <button 
                            className={styles.logout}
                            onClick={() => {
                                dispatch(defaultUser());
                                dispatch(onSignOutSuccess());
                            }}    
                        >Выйти</button>
                    </div>
                    
                </div>
            </div>
        );
    }   
};

const styles = {
    main: 'flex flex-col bg-green',
    div1: 'h-[60px] w-full bg-grey2 flex flex-row items-center justify-between px-[20px]',
    divLogo: 'shadow-[0_1px_8px_0px_rgba(0,0,0,0.12)] rounded-[12px] mr-[10px]',
    div1Sub: "flex flex-row items-center",
    div2: 'h-[45px] w-full bg-green flex flex-row items-center justify-start px-[20px]',
    logo: 'w-[45px] h-[45px] rounded-[12px]',
    divRight: 'flex flex-row',
    btn: 'mr-[20px] text-main font-medium text-[16px] px-[10px] py-[5px] ',
    name: 'mobile:hidden tablet:hidden laptop:flex desktop:flex text-[20px] font-medium mr-[10px]',
    profile: 'flex text-[20px] font-medium mr-[10px]',
    role: 'text-[20px] mr-[10px] font-medium',
    logout: 'text-black font-medium text-[18px]'
}
import * as React from 'react';

export const Footer = () => {
    if(window.location.pathname.includes('login')) {
        return(<></>)
    } else {
        return(<></>)
        // return(
        //     <div className={styles.main}>
        //         2022 г.
        //     </div>
        // );
    }
};

const styles = {
    main: 'flex flex-row items-center justify-center text-main h-[60px] font-medium text-[18px] bg-green'
}
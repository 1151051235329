import * as React from 'react';

const subTypes = [
    'Физическое лицо',
    'Самозанятый',
    'ИП',
    'Организация'
];

export const ModalSubTypeUser = (props) => {
    const {value, onChange, close} = props;
    const [data, setData] = React.useState(value);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Выберите</p>
                {
                    subTypes.map((item, index) =>
                        <button
                            key={index.toString()}
                            className={styles.btn + `${data === item? 'bg-main text-white ': 'bg-[#FDFFFD] text-black'}`}
                            onClick={() => { setData(item); }}
                        >{item}</button>
                    )
                }
                <div className={styles.divBtn}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { close(); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnChoose}
                        onClick={() => { onChange(data); close(); }}
                    >Выбрать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute top-[0px] left-0 flex items-center justify-center w-full ',
    div: 'shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] top-[50px] bg-white p-[20px] overflow-y-scroll rounded-[12px] w-[50%] flex flex-col',
    title: 'text-[20px] font-medium mb-[20px]',
    btn: 'w-1/3 h-[48px] rounded-[12px] font-medium ',
    divBtn: 'mt-[20px] flex flex-row gap-[10px]',
    btnClose: 'w-1/3 h-[48px] bg-grey rounded-[12px] font-medium text-black text-[16px]',
    btnChoose: 'w-1/3 h-[48px] bg-main rounded-[12px] font-medium text-white text-[16px]'
};
import * as React from 'react';
import {Request} from '../../requests/request';

export const ModalCategory = (props) => {
    const {value, onChangeValue, close, limit} = props;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [choose, setChoose] = React.useState([]);

    React.useEffect(() => {
        setChoose(value.map(item => item));
        (async function() {
            try {
                setLoading(true);
                let response = await Request('/api/categories/active', 'GET', null, null);
                if(response.status === 200) {
                    setData(response.data.data);
                }
                setLoading(false);
            } catch(e) {
                console.log('e', e); setLoading(false);
            };
        })();
        
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Выберите</p>
                {
                    data.map((item, index) => 
                        <button
                            key={index.toString()}
                            className={styles.btn + ` ${choose.map((item2, index2) => item2.name).includes(item.name)? 'bg-main text-white': 'bg-white text-black'}`}
                            onClick={() => { 
                                if(choose.map((item2) => item2._id).includes(item._id)) {
                                    setChoose(choose.filter((item2, index2) => item2._id !== item._id))
                                } else {
                                    if(choose.length < limit) {
                                        setChoose([...choose, item])
                                    };
                                };
                            }}
                        >{item.name}</button>
                    )
                }
                <div className='flex flex-row'>
                {
                    choose.map((item, index) => <label key={index.toString()}>{item.name}</label>)
                }
                </div>
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { close(); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnChoose}
                        onClick={() => { onChangeValue(choose); close(); }}
                    >Выбрать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute top-[0px] left-0 flex items-center justify-center w-full ',
    div: 'shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] top-[50px] bg-white p-[20px] overflow-y-scroll rounded-[12px] w-[50%] flex flex-col',
    title: 'text-[16px] font-medium text-[20px]',
    btn: 'h-[41px] rounded-[12px] mb-[8px] font-medium',
    divBtns: 'flex flex-row mt-[20px] gap-[8px]',
    btnClose: 'h-[48px] bg-grey w-1/3 rounded-[12px] text-black font-medium',
    btnChoose: 'h-[48px] bg-main w-1/3 rounded-[12px] text-white font-medium'
};
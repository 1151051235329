import * as React from 'react';

export const ViewText = (props) => {
    const {text, image} = props;

    return(
        <div className={styles.main}>
            <img src={image} className='mr-[8px]' />
            <label className={styles.text}>{text}</label>
        </div>
    );
};

const styles = {
    main: 'h-[48px] rounded-[12px] flex flex-row w-1/3 border-[#BFE9CB] border-[1px] items-center px-[12px] mb-[16px]',
    text: 'text-[20px] font-regular text-[#A6A6A6]'
}
import * as React from 'react';

export const Input = (props) => {
    const {
        value, onChange, placeholder, image, type
    } = props;

    return(
        <div className={styles.main}>
            <img className={styles.img} src={image} />
            <input 
                className={styles.input + ' outline-none'} 
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
            />
        </div>
    );
};

const styles = {
    main: 'flex flex-row items-center border-[#BFE9CB] border-[1px] rounded-[12px] w-1/3 h-[48px] p-[12px] mb-[16px]',
    img: 'mr-[8px]',
    input: 'text-[20px] font-regular text-[#2E2E2E] outline-none w-full'
}
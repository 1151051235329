import * as React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';

import {MainRoutes} from './Routes';

import {Header} from './components/Header';
import {Footer} from './components/Footer';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from './redux/store';
import { SideBar } from './components/SideBar';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className='flex flex-col justify-between w-[100%] h-[100%]'>
          <Router>
            <Header />
            <div 
              className='relative flex flex-row h-full overflow-y-scroll'
            >
              <SideBar />
              <MainRoutes />
            </div>
            <Footer />
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;

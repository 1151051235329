import * as React from 'react';

export const Switch = (props) => {
    const {value, text, onChange} = props;

    return(
        <div className={styles.main}>
            <p className={styles.text}>{text}</p>
            <div onClick={() => { onChange(!value); }} className={styles.view1 + `${value? 'bg-[#14AF3F]': 'bg-[#9C9C9C]'}`}>
                <div className={styles.round + `${value? ' translate-x-[20px]': ' -translate-x-[0px]'}`} />
            </div>
        </div>
    );
};

const styles = {
    main: 'w-1/3 flex flex-row items-center justify-between mb-[16px]',
    text: '',
    view1: 'w-[48px] h-[26px] rounded-[13.5px] flex items-center relative ',
    round: 'w-[20px] h-[20px] bg-white rounded-[10px] absolute left-[3px] '
}
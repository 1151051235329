import * as React from 'react';


export const Gender = (props) => {
    const {
        value, onChange
    } = props;

    return(
        <div className={styles.main}>
            <button
                className={styles.btn(value, 'Мужской')}
                onClick={() => { onChange('Мужской'); }}
            >Мужской</button>
            <button
                className={styles.btn(value, 'Женский')}
                onClick={() => { onChange('Женский'); }}
            >Женский</button>
        </div>
    );
};

const styles = {
    main: 'flex flex-row items-center justify-between w-1/3 gap-[8px] mb-[16px]',
    btn: (value, name) => `h-[48px] w-full rounded-[12px] font-regular text-[16px] ${value === name? 'bg-[#14AF3F] text-white': 'bg-[#F3F8F3] text-black'}`
}
import * as React from 'react';

export const CheckBox = (props) => {
    const {
        value, onChange, text
    } = props;

    return(
        <button
            className={styles.main}
            onClick={() => {
                onChange(!value);
            }}
        >
            <div className={styles.box}>
                {
                    value?
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 5L4.83333 8.5L11.5 1.5" stroke="#14AF3F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>:
                        <></>
                }
            </div>
            <p className='ml-[10px] text-[16px] font-regular'>{text}</p>
        </button>
    );
};

const styles = {
    main: 'mt-[10px] flex flex-row items-center',
    box: 'border-main rounded-[2px] w-[20px] h-[20px] border-[1px] flex flex-col items-center justify-center',
    circle: 'w-[13px] h-[13px] rounded-[10px]'
}
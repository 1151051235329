import * as React from 'react';
import { ModalAddCategory } from '../../components/modals/ModalAddCategory';
import {Request} from '../../requests/request';
import { useSelector, useDispatch } from 'react-redux';
import { RenderCategory } from '../../components/RenderCategory';
import { ModalEditCategory } from '../../components/modals/ModalEditCategory';
import { setCategoriesRedux } from '../../redux/categories/categories';
import { Loader } from '../../components/Loader';

export const Category = () => {
    const categoriesRedux = useSelector(state => state.categories.categories);
    const [data, setData] = React.useState(categoriesRedux);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);
    const token = useSelector(state => state.auth.session.token);
    const dispatch = useDispatch();

    const [modalAdd, setModalAdd] = React.useState(false);
    const [modalEdit, setModalEdit] = React.useState(false);
    const [modalEditIndex, setModalEditIndex] = React.useState(null);

    const [search, setSearch] = React.useState('');
    //const [modalDelete, setModalDelete] = React.useState(false);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            try {
                let tempData = Request(`/get/categories`, 'get', null, null);
                tempData.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                        dispatch(setCategoriesRedux(json.data.data));
                    }
                    setLoading(false);
                });
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
        return () => refRepeat.current = true;
    }, []);

    React.useEffect(() => {
        try {
            if(search !== '') {
                let response = Request(`/api/categories/search/admin?search=${search}`, 'get', token, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    }
                    setLoading(false);
                })
            } else {
                let tempData = Request(`/get/categories`, 'get', null, null);
                tempData.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                        dispatch(setCategoriesRedux(json.data.data));
                    }
                    setLoading(false);
                });
            }
        } catch(e) {
            console.log('e', e);
            setLoading(false);
        }
        
    }, [search]);

    return(
        <div className={styles.main}>
            <div className='flex flex-row items-start justify-between mt-[10px]'>
                <p className={styles.title}><label className={styles.label}>Категории</label>: Найдено {data.length}</p>
            </div>
            <div
                className='flex flex-row items-center justify-between mt-[10px]'
            >
                <input 
                    value={search}
                    onChange={(event) => { setSearch(event.target.value); }}
                    className={styles.search}
                    placeholder="Поиск"
                />
                <button
                    className={styles.btnAdd}
                    onClick={() => {
                        setModalAdd(true);
                    }}
                >Добавить</button>
            </div>
            <div className='grid grid-cols-1 laptop:grid-cols-2 mt-[10px]'>
            {
                loading?
                    <Loader />:
                    data.map((item, index) => 
                        <RenderCategory 
                            item={item}
                            index={index}
                            onEdit={() => { setModalEditIndex(index); setModalEdit(true); }}
                            token={token}
                            key={index.toString()}
                            onDeleteSuccess={() => {
                                try {
                                    let tempData = Request(`/get/categories`, 'get', null, null);
                                    tempData.then((json) => {
                                        if(json.status === 200) {
                                            setData(json.data.data);
                                        }
                                        setLoading(false);
                                    });
                                } catch(e) {
                                    console.log('e', e);
                                    setLoading(false);
                                };
                            }}
                        />
                    )
            }
            </div>
            {
                modalAdd &&
                <ModalAddCategory 
                    close={() => {setModalAdd(false);}}
                    onSuccess={() => {
                        setModalAdd(false);
                        setLoading(true);
                        try {
                            let tempData = Request(`/get/categories`, 'get', null, null);
                            tempData.then((json) => {
                                if(json.status === 200) {
                                    setData(json.data.data);
                                }
                                setLoading(false);
                            });
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                    token={token}
                />
            }
            {
                modalEdit && 
                <ModalEditCategory 
                    token={token}
                    item={data[modalEditIndex]}
                    close={() => { setModalEdit(false); }}
                    onSuccess={() => {
                        setModalEdit(false);
                        setLoading(true);
                        try {
                            let tempData = Request(`/get/categories`, 'get', null, null);
                            tempData.then((json) => {
                                if(json.status === 200) {
                                    setData(json.data.data);
                                }
                                setLoading(false);
                            });
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: "container relative px-[20px] h-full overflow-y-scroll",
    title: 'text-[20px] font-medium',
    label: 'text-main',
    btnAdd: 'bg-main text-white font-medium px-[20px] py-[10px] rounded-[12px] w-[150px] items-center justify-center',
    search: 'border-main border-[1px] rounded-[12px] text-[16px] font-regular outline-main px-[20px] h-[45px] w-1/3'
};
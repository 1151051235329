import * as React from 'react';
import { CheckBox } from '../CheckBox';
import { Request } from '../../requests/request';

export const ModalEditRegions = (props) => {
    const {
        item, close, token, onSuccess
    } = props;

    const [data, setData] = React.useState(item);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Изменить регион</p>
                <p className={styles.label}>Название</p>
                <input 
                    className={styles.input}
                    value={data.name}
                    onChange={(event) => {
                        setData({
                            ...data, name: event.target.value
                        })
                    }}
                />
                <p className={styles.label}>Код региона</p>
                <input 
                    className={styles.input}
                    value={data.code}
                    onChange={(event) => {
                        setData({
                            ...data, code: event.target.value
                        });
                    }}
                />
                <CheckBox 
                    value={data.visible}
                    onChange={(value) => setData({...data, visible: value})}
                    text="Активный"
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            close();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnSave}
                        onClick={() => {
                            try {
                                let response = Request(`/put/region`, 'put', token, JSON.stringify({
                                    data: {
                                        id: item._id,
                                        ...data
                                    }
                                }));

                                response.then((json) => {
                                    if(json.status === 200 && json.data.status === 'success') {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                            };
                        }}
                    >Изменить</button>
                </div>
            </div>
            
        </div>
    );
};

const styles = {
    main: 'absolute modal top-0 left-0 flex items-center justify-center w-full h-full',
    div: 'bg-white p-[20px] rounded-[12px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] w-[50%]',
    title: 'text-[20px] font-medium',
    label: 'text-[16px] font-regular text-grey-text mt-[10px]',
    input: 'border-main border-[1px] rounded-[12px] px-[12px] py-[10px] w-full mt-[10px]',
    divBtns: 'mt-[20px] flex flex-row items-center justify-between',
    btnClose: 'bg-grey px-[20px] py-[10px] rounded-[12px] text-black font-medium',
    btnSave: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium'
}
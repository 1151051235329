import * as React from 'react';
import typeUserSvg from '../../assets/icons/typeUser.svg';
import downSvg from '../../assets/icons/Down.svg'

export const Select = (props) => {
    const {value, onChange} = props;

    return(
        <div className={styles.main} onClick={() => {onChange(); }}>
            <div className='flex flex-row'>
                <img src={typeUserSvg} className='mr-[8px]' />
                <p className={styles.text}>{value}</p>
            </div>
            <img src={downSvg} />
        </div>
    );
};

const styles = {
    main: "py-[10px] w-1/3 border-[#BFE9CB] border-[1px] flex items-center justify-between px-[12px] rounded-[12px] mb-[16px]",
    text: 'text-[20px] font-regular text-[#A6A6A6] font-regular line-clamp-1',

}
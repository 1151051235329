import * as React from 'react';


export const RenderVacations = (props) => {
    const {
        item, index, onClick
    } = props;

    return(
        <div 
            key={index.toString()} 
            className={styles.main(index)}
            onClick={() => { onClick(index); }}
        >
            <p className={styles.name}><label className={styles.labelText}>Заказчик:</label> <label className={styles.label + ' text-main'}>{item.name} {item.surname}</label></p>
            <p className={styles.created}><label className={styles.labelText}>Дата создания:</label> <label className={styles.label}>{new Date(item.created).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</label></p>
            <p className={styles.typeWork}><label className={styles.labelText}>Характер работы:</label> <label className={styles.label}>{item.typeWork}</label></p>
            <p className={styles.price}><label className={styles.labelText}>Цена:</label> <label className={styles.label}>{item.price.minPrice} - {item.price.maxPrice} {item.typePrice}</label></p>
            <p className={styles.descr}><label className={styles.labelText}>Описание:</label> <label className={styles.label}>{item.description}</label></p>
            <p><label className={styles.labelText}>Статус активности:</label> <label className={styles.visible(item.visible)}>{item.visible? 'Активно': "Не активно"}</label></p>
        </div>
    );
};

const styles = {
    main: (index) => `bg-white mb-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] rounded-[12px] p-[20px] ${index === 0? 'mt-[20px] ': ''}`,
    name: 'text-[16px] ',
    visible: (visible) => `text-[16px] font-regular ${visible? 'text-main ': 'text-red'}`,
    typeWork: '',
    price: 'text-black font-regular',
    descr: 'mt-[10px]',
    created: 'font-regular',
    label: 'font-medium',
    labelText: 'font-regular'
}
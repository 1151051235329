import {api} from '../configs/api';

export const GetData = async (route, token, responseFunction, errorFunction) => {
    await fetch(api + route, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token !== null? `Bearer ${token}`: null
        }
    })
    .then((response) => {
        let status = response.status;
        let data = response.json();

        return Promise.all([data, status]);
    })
    .then(([json, statusCode]) => {
        responseFunction(json, statusCode);
    })
    .catch((e) => {
        errorFunction(e);
    });
};
import * as React from 'react';

export const DisplayMap = (props) => {
    const {value, onChange} = props;

    return(
        <div className={styles.main}>
            <img onClick={()=> onChange(!value)} className='w-[20px] h-[20px]' src={value? require('../../assets/icons/icon-checkMark-active.png'): require('../../assets/icons/icon-checkMark-gray.png')} />
            <label className={styles.label}>Отображать на карте</label>
        </div>
    );
};

const styles = {
    main: 'flex flex-row items-center mb-[16px]',
    div: 'w-[20px] h-[20px] border-[#A6A6A6] rounded-[4px] border-[2px]',
    div2: '',
    label: 'ml-[8px] text-[15px] font-regular'
};
import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    categories: []
};

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategoriesRedux: (state, action) => {
            state.categories = action.payload;
        }
    }
});

export const {setCategoriesRedux} = categoriesSlice.actions;

export default categoriesSlice.reducer;
import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const middlewares = [];

const persistConfig = {
    key: 'root',
    'keyPrefix': '',
    storage,
    whitelist: ['auth', 'categories', 'subcategories', 'users']
};

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false, serializableCheck:false
    })
    .concat(middlewares)
});

export const persistor = persistStore(store);

export default store;
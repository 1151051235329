import * as React from 'react';
import { CheckBox } from '../CheckBox';
import { Request } from '../../requests/request';


export const ModalAddCategory = (props) => {
    const {close, onSuccess, token} = props;
    const [name, setName] = React.useState('');
    const [visible, setVisible] = React.useState(false);
    const [useChild, setUseChild] = React.useState(false);
    const [child, setChild] = React.useState([]);
    const [inputChild, setInputChild] = React.useState('');

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Новая категория</p>
                <p className={styles.label}>Имя</p>
                <input 
                    className={styles.input}
                    value={name}
                    onChange={(event) => { setName(event.target.value); }}
                />
                <CheckBox 
                    value={visible}
                    onChange={(value) => {
                        setVisible(value);
                    }}
                    text="Отображать пользователям"
                />
                {
                    !useChild?
                        <button
                            className={styles.btnAddChild}
                            onClick={() => { setUseChild(true); }}
                        >Добавить подкатегории</button>:
                        <div className='mt-[10px]'>
                            <div className='flex flex-row'>
                                <input 
                                    className='border-main border-[1px] h-[41px] rounded-[10px] w-3/5 outline-main px-[20px]'
                                    value={inputChild}
                                    onChange={(event) => { setInputChild(event.target.value); }}
                                />
                                <button  
                                    className='ml-[10px] bg-main w-1/5 text-white rounded-[10px] font-medium'
                                    onClick={() => {
                                        if(inputChild !== '') {
                                            let temp = child;
                                            child.push(inputChild);
                                            setChild(temp);
                                            setInputChild('');
                                        } else {
                                            alert('Подкатегория не может быть пустым значением')
                                        };
                                    }}
                                >Добавить</button>
                                <button 
                                    className='ml-[10px] bg-grey w-1/5 rounded-[10px] font-medium'
                                    onClick={() => { setUseChild(false); }}
                                >Закрыть</button>
                            </div>
                            
                        </div>
                }
                {
                    child.length > 0?
                    <div className='overflow-y-scroll'>
                        <p className='text-medium mt-[10px]'>Подкатегории:</p>
                        {
                            child.map((item, index) => 
                                <div 
                                    className='w-full flex flex-row items-center justify-between mt-[5px]'
                                    key={index.toString()}
                                >
                                    <label>{item}</label>
                                    <button
                                        className='text-red'
                                        onClick={() => {
                                            setChild(child.filter((item2, index2) => index2 !== index))
                                        }}
                                    >Удалить</button>
                                </div>
                            )
                        }
                    </div>:
                    <></>
                }
                
                <div className={styles.divBtns}>
                    <button 
                        className={styles.btnCancel}
                        onClick={() => {
                            close();
                        }}
                    >Отмена</button>
                    <button
                        className={styles.btnSave}
                        onClick={() => {
                            if(name.length > 0) {
                                const response = Request(`/post/category`, 'post', token, JSON.stringify({
                                    data: {
                                        name: name,
                                        visible: visible,
                                        child: child
                                    }
                                }));
                                response.then((json) => {
                                    if(json.status === 200 && json.data.status === 'success') {
                                        onSuccess();
                                    }
                                })
                            };
                        }}
                    >Создать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute w-full h-full modal top-0 flex items-center justify-center',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-3/5',
    title: "text-[20px] font-medium text-black",
    label: 'mt-[10px] text-[16px] text-grey-text font-regular',
    input: 'mt-[10px] px-[12px] outline-none border-main border-[1px] rounded-[12px] py-[10px] text-[16px] font-regular w-full',
    divBtns: 'flex flex-row items-center justify-between mt-[20px]',
    btnCancel: 'px-[20px] py-[10px] rounded-[12px] bg-grey text-black font-medium',
    btnSave: 'px-[20px] py-[10px] rounded-[12px] bg-main text-white font-medium',
    btnAddChild: 'w-3/5 h-[41px] mt-[10px] bg-main text-white rounded-[10px] font-medium'
}
import * as React from 'react';
import {Request} from '../requests/request';

export const RenderCities = (props) => {
    const {item, index, token, onDeleteSuccess, onEdit} = props;

    return(
        <div className={styles.main(index)}>
            <div 
                className={styles.div}
                onClick={() => { onEdit(); }}    
            >
                <p className={styles.name}>{item.name}</p>
                <p className={styles.visible(item.visible)}>{item.visible? 'Активно': 'Не активно'}</p>
            </div>
            <button
                className={styles.btnDelete}
                onClick={() => {
                    try {
                        let response = Request(`/delete/city`, 'delete', token, JSON.stringify({
                            id: item._id
                        }))
                        response.then((json) => {
                            if(json.status === 200 && json.data.status === 'success') {
                                onDeleteSuccess();
                            }
                        })
                    } catch(e) {
                        console.log('e', e);
                    };
                }}
            >Удалить</button>
        </div>
    );
};

const styles = {
    main: (index) => `flex flex-row items-center justify-between p-[20px] rounded-[12px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] mb-[20px] bg-white ${index === 0? 'mt-[20px] ': ''}`,
    div: 'w-full',
    name: 'text-[16px] font-medium text-black',
    visible: (visible) => `text-[16px] font-regular mt-[5px] ${visible? 'text-main ': "text-red "}`,
    btnDelete: 'px-[20px] py-[10px] text-red font-medium'
}
import {api} from '../configs/api';

export const Request = async (route, method='get', token, body) => {
    let configs = {method: method};
    
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if(token) {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    if(method === 'GET') {
        configs = {
            method: method,
            headers: headers
        };
    } else {
        configs = {
            method: method,
            headers: headers,
            body: body
        };
    }

    const response = await fetch(api + route, configs);

    let status = response.status;

    // console.log('status', status);
    let data = await response.json();
    return {data, status}; //Promise.all([data, status]);
}
import * as React from 'react';

const getName = (data) => {
    if(data.typeUser === 'Performer') {
        if(data.subTypeUser_performer === 'Организация') {
            return data.short_name_org_performer + '/' + data.surname + ' ' + data.name + ' ' + data.midname;
        } else {
            return data.surname + ' ' + data.name + ' ' + data.midname;
        };
    } else {
        if(data.subTypeUser_client === 'Организация') {
            return data.short_name_org_client + '/' + data.surname + ' ' + data.name + ' ' + data.midname;
        } else {
            return data.surname + ' ' + data.name + ' ' + data.midname;
        };
    };
};

export const RenderUser = (props) => {
    const { item, index, navigate } = props;
    
    return(
        <button 
            className={styles.main} 
            key={index.toString()}
            onClick={() => {
                navigate(`/users/${item._id}`, {
                    state: item
                });
            }}    
        >
            <p className={styles.p}>Тип пользователя: <label className={styles.label}>{item.typeUser === 'Performer'? 'Исполнитель': 'Клиент'} - {item.typeUser === 'Performer'? item.subTypeUser_performer: item.subTypeUser_client}</label></p>
            <p className={styles.p}>ФИО/Организация: <label className={styles.label}>{getName(item)}</label></p>
            <p className={styles.p}>Телефон: <label className={styles.label}>{item.phone !== ''? item.phone: 'Не задан'}</label></p>
            <p className={styles.p}>Зарегистрирован: <label className={styles.label}>{new Date(item.registered).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</label></p>
        </button>
    );
};

const styles = {
    main: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] mx-[20px] p-[20px] rounded-[12px] flex flex-col',
    p: 'text-[16px] font-regular text-grey-text',
    label: 'font-medium text-black'
};
import * as React from 'react';
import { ModalAddRegions } from '../../components/modals/ModalAddRegions';
import { ModalEditRegions } from '../../components/modals/ModalEditRegions';
import {Request} from '../../requests/request';
import {useSelector} from 'react-redux';
import { RenderRegions } from '../../components/RenderRegions';
import { Loader } from '../../components/Loader';

export const Regions = () => {
    const token = useSelector(state => state.auth.session.token);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    const [modalAdd, setModalAdd] = React.useState(false);
    const [modalEdit, setModalEdit] = React.useState(false);
    const [modalEditIndex, setModalEditIndex] = React.useState(null);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            try {
                let response = Request(`/get/regions`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setData(json.data.data);
                    };
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}><label className={styles.label}>Регионы</label>: Найдено {data.length}</p>
                
            </div>
            <div className='flex mt-[10px] items-center justify-end'>
                <button 
                    className={styles.btnCreate}
                    onClick={() => {
                        setModalAdd(true);
                    }}
                >Создать</button>
            </div>
            <div className='grid grid-cols-1 laptop:grid-cols-2 gap-[20px]'>
            {
                loading?
                    <Loader />:
                    data.map((item, index) => 
                        <RenderRegions 
                            key={index.toString()}
                            item={item}
                            index={index}
                            token={token}
                            onDeleteSuccess={() => {
                                setModalEdit(false);
                            }}
                            onEdit={() => {
                                setModalEdit(true);
                                setModalEditIndex(index);
                            }}
                        />
                    )
            }
            </div>
            {
                modalAdd &&
                <ModalAddRegions 
                    close={() => { setModalAdd(false); }}
                    token={token}
                    onSuccess={() => {
                        setModalAdd(false);
                        try {
                            let response = Request(`/get/regions`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    setData(json.data.data);
                                };
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
            {
                modalEdit && 
                <ModalEditRegions 
                    item={data[modalEditIndex]}
                    close={() => { setModalEdit(false); }}
                    token={token}
                    onSuccess={() => {
                        setModalEdit(false);
                        try {
                            let response = Request(`/get/regions`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    setData(json.data.data);
                                };
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: 'container overflow-y-scroll px-[20px] h-full',
    title: 'px-[20px] text-[20px] font-medium',
    label: 'text-main',
    div: 'mt-[10px] flex flex-row items-center justify-between',
    btnCreate: 'px-[20px] py-[10px] rounded-[12px] text-[16px] font-medium text-white w-[150px] bg-main'
}
export const getRole = (role) => {
    switch(role) {
        case 'moderator': return 'Модератор';
        case 'admin': return 'Администратор';
        default: return '';
    }
};

export const getRoleRevert = (role) => {
    switch(role) {
        case 'Модератор': return 'moderator';
        case 'Администратор': return 'admin';
        default: return '';
    }
};

export const getUserType = (typeUser) => {
    switch(typeUser) {
        case 'Performer': return 'Исполнитель';
        case 'Client': return 'Заказчик';
        default: return '';
    }
};

export const getUserTypeRevert = (typeUser) => {
    switch(typeUser) {
        case 'Исполнитель': return 'Performer';
        case 'Заказчик': return 'Client';
        default: return '';
    }
};

export const subTypeUserData = [
    { name: 'Физическое лицо' },
    { name: 'Самозанятый' },
    { name: 'ИП' },
    { name: 'Организация' }
];

export const typeUserData = [
    { name: 'Исполнитель' },
    { name: 'Заказчик' }
];

export const sortUserData = [
    { name: 'По имени' },
    { name: 'По типу пользователя' }
];

export const typePrice = [
    {name: "₽ / в час"}, {name: '₽ / в день'}, {name: '₽ / в месяц'}, {name: 'Сдельный'}
]

export const getNameOrNameOrg = (data) => {
    let typeUser = data.typeUser;
    let subTypeUser = data.subTypeUser;

    let result = '';
    if(typeUser === 'Performer') {
        if(subTypeUser === 'Физическое лицо') {
            result += data.forms.performers.physical.name + ' ' + data.forms.performers.physical.surname;
        } else {
            if(subTypeUser === 'Самозанятый') {
                result += data.forms.performers.selfEmployed.name + ' ' + data.forms.performers.selfEmployed.surname;
            } else {
                if(subTypeUser === 'ИП') {
                    if(data.forms.performers.ip?.nameOrg !== '') {
                        result += data.forms.performers.ip.nameOrg;
                    } else {
                        result += data.forms.performers.ip.name + ' ' + data.forms.performers.ip.surname;
                    };
                } else {
                    if(subTypeUser === 'Организация') {
                        if(data.forms.performers.organization?.nameOrg !== '') {
                            result += data.forms.performers.organization.nameOrg;
                        } else {
                            result += data.forms.performers.organization.name + ' ' + data.forms.performers.organization.surname; 
                        }
                        
                    }
                }
            }
        }
    } else {
        if(typeUser === 'Client') {
            if(subTypeUser === 'Физическое лицо') {
                result += data.forms.clients.physical.name + ' ' + data.forms.clients.physical.surname;
            } else {
                if(subTypeUser === 'Самозанятый') {
                    result += data.forms.clients.selfEmployed.name + ' ' + data.forms.clients.selfEmployed.surname;
                } else {
                    if(subTypeUser === 'ИП') {
                        if(data.forms.clients.ip?.nameOrg !== '') {
                            result += data.forms.clients.ip.nameOrg;
                        } else {
                            result += data.forms.clients.ip.name + ' ' + data.forms.clients.ip.surname;
                        };
                    } else {
                        if(subTypeUser === 'Организация') {
                            if(data.forms.clients.organization?.nameOrg !== '') {
                                result += data.forms.clients.organization.nameOrg;
                            } else {
                                result += data.forms.clients.organization.name + ' ' + data.forms.clients.organization.surname;
                            };
                        }
                    }
                }
            }
        }
        
    } 

    return result;
};

export const getCurrentTypeUser = (data) => {
    if(data.typeUser === 'Performer') {
        switch(data.subTypeUser) {
            case 'Физическое лицо': return data.forms.performers.physical;
            case 'Самозанятый': return data.forms.performers.selfEmployed;
            case 'ИП': return data.forms.performers.ip;
            case 'Организация': return data.forms.performers.organization;
        }
    } else {
        if(data.typeUser === 'Client') {
            switch(data.subTypeUser) {
                case 'Физическое лицо': return data.forms.clients.physical;
                case 'Самозанятый': return data.forms.clients.selfEmployed;
                case 'ИП': return data.forms.clients.ip;
                case 'Организация': return data.forms.clients.organization;
            }
        } else {
            return {
                name: '',
                surname: '',
                midname: '',
                avatar: '',
                inn: '',
                innVerified: false,
                address: '',
                geolocation: {
                    latitude: 0,
                    longitude: 0
                },
                experience: '',
                vaccination: '',
                sanitary: '',
                driverLicense: '',
                character: '',
                sphere: '',
                profession: '',
                priceType: '',
                minPrice: 0,
                maxPrice: 0,
                birthdate: new Date(),
                nationality: '',
                gender: '',
                aboutMe: '',
                visible: false,
                edited: new Date()
            };
        };
    }
};

export const getFormsByTypeUser = (data, typeUser, subTypeUser) => {
    if(typeUser === 'Исполнитель') {
        switch(subTypeUser) {
            case 'Физическое лицо': return data.forms.performers.physical;
            case 'Самозанятый': return data.forms.performers.selfEmployed;
            case 'ИП': return data.forms.performers.ip;
            case 'Организация': return data.forms.performers.organization;
            default: return data.forms.performers.physical;
        };
    } else {
        switch(subTypeUser) {
            case 'Физическое лицо': return data.forms.clients.physical;
            case 'Самозанятый': return data.forms.clients.selfEmployed;
            case 'ИП': return data.forms.clients.ip;
            case 'Организация': return data.forms.clients.organization;
            default: return data.forms.clients.physical;
        };
    };
};

export const getBirthDateFormat = (date) => {
    let month = (date.getMonth() + 1) > 9? (date.getMonth() + 1) + '': '0' + (date.getMonth() + 1)
    return date.getFullYear() + '-' + month + '-' + date.getDate()
};

export const getBirthDate = (str) => {
    let year = str.substring(0, 4);
    let month = str.substring(5, 7);
    let day = str.substring(8)
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};
import * as React from 'react';
import { Request } from '../requests/request';

export const RenderNationality = (props) => {
    const {item, index, onSuccess, token, onClick} = props;

    return(
        <div 
            key={index.toString()} 
            className={styles.main(index)}
        >
            <div className='flex flex-col w-full' onClick={() => { onClick(index);}}>
                <p className={styles.name}><label className={styles.label}>Название:</label> <label className={styles.labelValue}>{item.name}</label></p>
                <p><label className={styles.label}>Статус активности:</label> <label className={styles.visible(item.visible)}>{item.visible? 'Активно': "Не активно"}</label></p>
            </div>
            <button
                className={styles.btnDelete}
                onClick={() => {
                    try {
                        let response = Request('/api/nationalities', 'delete', token, JSON.stringify({
                            id: item._id
                        }));
                        response.then((json) => {
                            if(json.status === 200) {
                                onSuccess();
                            }
                        })
                    } catch(e) {
                        console.log('e', e);
                    }
                }}
            >Удалить</button>
        </div>
    );
};

const styles = {
    main: (index) => `bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] ${index === 0? 'mt-[20px] ': 'mt-0 '} mb-[20px] flex flex-row items-center justify-between`,
    name: 'text-black',
    visible: (visible) => `${visible? 'text-main ': 'text-red '} font-medium text-[16px]`,
    btnDelete: 'text-red font-medium text-[16px] px-[20px] py-[10px]',
    label: 'font-regular text-[16px]',
    labelValue: 'font-medium text-[20px]'
}
import * as React from 'react';
import { ModalEditVacations } from '../../components/modals/ModalEditVacations';
import { RenderVacations } from '../../components/RenderVacations';
import {Request} from '../../requests/request';
import { useSelector } from 'react-redux';
import { ModalAddVacation } from '../../components/modals/ModalAddVacation';
import { CustomSelect2 } from '../../components/CustomSelect';
import { Pagination } from '../../components/Pagination';
import { pageCount } from '../../configs/constant';
import { Loader } from '../../components/Loader';

const sortData = [
    {
        value: 'По названию заказчика', label: 'По названию заказчика'
    },
    {
        value: 'По характеру работы', label: 'По характеру работы'
    },
    {
        value: 'По вознаграждению', label: 'По вознаграждению'
    }
]

export const Vacations = () => {
    const token = useSelector(state => state.auth.session.token);
    const categoryRedux = useSelector(state => state.categories.categories);
    const usersRedux = useSelector(state => state.users.users);
    const subcategoriesRedux = useSelector(state => state.subcategories.subcategories);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    const [modalEditVisible, setModalEditVisible] = React.useState(false);
    const [modalEditIndex, setModalEditIndex] = React.useState(-1);
    const [modalAddVisible, setModalAddVisible] = React.useState(false);

    const [search, setSearch] = React.useState('');
    const [sort, setSort] = React.useState('');
    const [page, setPage] = React.useState(1);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            try {
                let response = Request(`/api/vacations`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    }
                    setLoading(false);
                });
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        } 
        return () => refRepeat.current = true;
    }, []);

    React.useEffect(() => {
        if(search !== '') {
            try {
                setLoading(true);
                let response = Request(`/api/vacations/search/admin?search=${search}`, 'get', token, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    }
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            }
        } else {
            setLoading(true);
            try {
                let response = Request(`/api/vacations`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    }
                    setLoading(false);
                });
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
    }, [search])

    console.log('data', data);

    return(
        <div className={styles.main}>
            <div className={styles.headerDiv}>
                <p className={styles.title}><label className={styles.label}>Вакансии</label>: Найдено {data.length}</p>
            </div>
            <div className='mt-[20px] flex flex-row items-center px-[20px] justify-start'>
                <div className='flex flex-row w-full'>
                    <CustomSelect2 
                        value={sort}
                        onChange={(value) => { setSort(value.value); }}
                        data={sortData}
                        className1='w-1/4'
                    />
                    <input 
                        value={search}
                        onChange={(event) => { setSearch(event.target.value); }}
                        className={styles.search}
                        placeholder="Поиск"
                    />
                </div>
                <button
                    className={styles.btnAdd}
                    onClick={() => { setModalAddVisible(true); }}
                >Добавить</button>
            </div>
            <div className='grid grid-cols-1 gap-[15px] px-[20px]'>
            {
                loading? 
                    <Loader />:
                    sortVacationData(data.filter((item, index) => index >= (page - 1) * pageCount && index < page * pageCount ), sort)
                    .map((item, index) => 
                        <RenderVacations 
                            item={item}
                            index={index}
                            key={index.toString()}
                            onClick={(index) => { setModalEditIndex(index); setModalEditVisible(true); }}
                        />
                    )
            }
            </div>
            {/* <div className='mt-[20px] flex flex-row justify-end mb-[10px]'>
                <Pagination 
                    page={page}
                    setPage={setPage}
                    length={data.length}
                />
            </div> */}
            {
                modalEditVisible && 
                <ModalEditVacations 
                    data={data[modalEditIndex]}
                    token={token}
                    categories={categoryRedux}
                    subcategories={subcategoriesRedux}
                    users={usersRedux}
                    onClose={() => { setModalEditVisible(false); }}
                    onSuccess={() => {
                        setModalEditVisible(false); 
                        setLoading(true);
                        try {
                            let response = Request(`/api/vacations`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200) {
                                    setData(json.data.data);
                                }
                                setLoading(false);
                            });
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
            {
                modalAddVisible && 
                <ModalAddVacation 
                    onClose={() => { setModalAddVisible(false); }}
                    token={token}
                    categories={categoryRedux}
                    subcategories={subcategoriesRedux}
                    users={usersRedux}
                    onSuccess={() => {
                        setModalAddVisible(false);
                        setLoading(true);
                        try {
                            let response = Request(`/api/vacations`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200) {
                                    setData(json.data.data);
                                }
                                setLoading(false);
                            });
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: "container w-full overflow-y-scroll",
    title: 'text-[20px] font-medium',
    label: 'text-main',
    search: 'border-main border-[1px] ml-[20px] rounded-[12px] w-1/3 text-[16px] text-black font-regular outline-main px-[20px] py-[10px]',
    headerDiv: 'px-[20px] flex flex-row justify-between mt-[10px]',
    btnAdd: 'px-[20px] py-[10px] w-[150px] bg-main text-white font-medium text-[16px] rounded-[12px] flex items-center justify-center',
    btnAddMobile: 'px-[20px] w-full py-[10px] bg-main text-white font-medium text-[16px] rounded-[12px] mobile:flex tablet:flex laptop:hidden desktop:hidden'
};

const sortVacationData = (data, sort) => {
    switch(sort) {
        case 'По названию заказчика': 
            return data.sort(function(a, b) {
                if( a.name + ' ' + a.surname > b.name + ' ' + b.surname) {
                    return 1;
                } else {
                    return -1;
                };
            });
        case 'По характеру работы': return data.sort(function(a, b) {
            return data.sort(function(a, b) {
                if(a.character > b.character) {
                    return 1;
                } else {
                    if(a.character < b.character){
                        return -1;
                    } else {
                        return 0;
                    }
                };
            });
        });
        case 'По вознаграждению': return data.sort(function(a, b) {
            if(a.price > b.price) {
                return 1;
            } else {
                if(a.price < b.price) {
                    return -1;
                } else {
                    return 0;
                };
            };
        });
        default: return data;
    } 
}
import * as React from 'react';
import { Request } from '../../requests/request';
import { CheckBox } from '../CheckBox';

export const ModalAddNationality = (props) => {
    const {onClose, token, onSuccess} = props;

    const [name, setName] = React.useState('');
    const [visible, setVisible] = React.useState(false);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Добавить Гражданство</p>
                <p className={styles.label}>Имя:</p>
                <input 
                    value={name}
                    onChange={(event) => { setName(event.target.value); }}
                    className={styles.input}
                />
                <CheckBox 
                    value={visible}
                    onChange={(value) => { setVisible(value); }}
                    text="Статус активности"
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            onClose();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnCreate}
                        onClick={() => {
                            try {
                                let response = Request('/api/nationalities', 'post', token, JSON.stringify({
                                    data: {
                                        name: name,
                                        visible: visible
                                    }
                                }));
                                response.then((json) => {
                                    if(json.status === 200) {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                            };
                        }}
                    >Создать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute modal flex items-center justify-center w-full h-full left-0 top-0',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium text-black',
    label: 'mt-[20px] text-[16px] font-regular text-grey-text',
    input: 'border-main border-[1px] outline-none text-[16px] font-regular px-[20px] py-[10px] mt-[10px] rounded-[12px] w-3/4',
    divBtns: 'mt-[20px] flex flex-row items-center justify-between',
    btnClose: 'px-[20px] py-[10px] bg-grey rounded-[12px] text-[16px] font-medium',
    btnCreate: 'px-[20px] py-[10px] bg-main rounded-[12px] text-[16px] font-medium text-white'
}
import * as React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

export const SideBar = () => {
    const navigate = useNavigate();
    const role = useSelector(state => state.auth.user.role);
    const email = useSelector(state => state.auth.user.email);
    const location = useLocation();

    if(window.location.pathname.includes('login')) {
        return(<></>);
    } else {
        return(
            <div className={styles.main}>
                <button
                    className={styles.btn(location.pathname === '/'? true: false)}
                    onClick={() => {
                        navigate('/');
                    }}
                >Пользователи</button>
                <button
                    className={styles.btn(window.location.pathname.includes('/vacations'))}
                    onClick={() => {
                        navigate('/vacations');
                    }}
                >Вакансии</button>
                <button
                    className={styles.btn(window.location.pathname.includes('/category'))}
                    onClick={() => {
                        navigate('/category');
                    }}
                >Категории</button>
                <button
                    className={styles.btn(window.location.pathname.includes('/subcategory'))}
                    onClick={() => {
                        navigate('/subcategory');
                    }}
                >Подкатегории</button>
                {/* <button
                    className={styles.btn(window.location.pathname.includes('/regions'))}
                    onClick={() => {
                        navigate('/regions');
                    }}
                >Регионы</button>
                <button 
                    className={styles.btn(window.location.pathname.includes('/cities'))}
                    onClick={() => {
                        navigate('/cities');
                    }}
                >Города</button> */}
                {
                    role === 'admin'?
                        <button
                            className={styles.btn(window.location.pathname.includes('/admins'))}
                            onClick={() => {
                                navigate('/admins');
                            }}
                        >Админы и модераторы</button>:
                        <></>
                }
                <button
                    className={styles.btn(window.location.pathname.includes('/complaints'))}
                    onClick={() => {
                        navigate('/complaints');
                    }}
                >Жалобы</button>
                <button
                    className={styles.btn(window.location.pathname.includes('/nationality'))}
                    onClick={() => {
                        navigate('/nationality');
                    }}
                >Гражданство</button>
                {
                    email === 'miise95mmaks@mail.ru'?
                        <button
                            className={styles.btn(window.location.pathname.includes('/logs'))}
                            onClick={() => { navigate('/logs') }}
                        >Логи</button>:<></>
                }
            </div>
        );
    }

    
};

const styles = {
    main: 'mobile:hidden tablet:hidden laptop:flex desktop:flex flex-col bg-green',
    btn: (active) => `text-start px-[20px] mt-[10px] text-[16px] font-medium ${active? 'text-main': 'text-black'}`
}
import * as React from 'react';

export const RenderLog = (props) => {
    const {item, index, setModalVisible, setModalValue} = props;
    return(
        <div 
            key={index.toString()} 
            className='mx-[20px] bg-white shadow-[0_0px_4px_0.3px_rgba(0,0,0,0.3)] px-[20px] rounded-[10px] py-[20px] mb-[20px]'
            onClick={() => { setModalValue(item); setModalVisible(true); }}    
        >
            <div>
                <p className='text-red font-medium'><label>{item.method}</label> <label>{item.route}</label></p>
                <p className='text-main font-medium text-[20px]'>{item.email}</p>
                <p className=' line-clamp-3'>{item.collections}</p>
                <p className=''>{new Date(item.created).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
            </div>
        </div>
    );
}
import * as React from 'react';

export const Label = (props) => {
    const {text} = props;

    return(
        <label className={styles.main + ''}>{text}</label>
    );
};

const styles = {
    main: 'mb-[4px] text-[16px] font-regular text-[#A6A6A6]'
};
import * as React from 'react';
import Select, {components} from 'react-select';


export const CustomSelect = (props) => {
    const { data, value, onChange, style } = props;

    return(
        <div className='flex mr-[20px]'>
            <div className='w-fit'>
                <select id="select" className={`form-select appearance-none
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-medium
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border-[1px] border-main
                    rounded-[5px]
                    transition
                    ease-in-out
                    m-0
                    outline-none
                `} 
                    aria-label="Default select example"
                    value={value}
                    onSelect={() => {
                        console.log('selec onSe');
                    }}
                    onChange={(event) => {
                        onChange(event.target.value);
                    }}
                >
                {
                    data.map((item, index) => 
                        <option key={index.toString()} value={item.name}>{item.name}</option>
                    )
                }
                </select>
            </div>
        </div>
    );
};

export const CustomSelect2 = (props) => {
    const {data, value, onChange, className1} = props;
    return(
        <Select 
            options={data}
            onChange={onChange}
            defaultValue={value}
            //value={value}
            className={className1}
            styles={{
                control: (baseStyles, state) => ({
                    borderColor: '#14AF3F', borderWidth: 1,
                    display: 'flex', borderRadius: 5,
                    paddingTop: 5, paddingBottom: 5
                })
            }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: '#E1F5E7',
                    primary: '#14AF3F'
                }
            })}
        />
    );
}
import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Label, Select, ViewText, Switch, DisplayMap,
    ViewCategories, Input, Gender, MultiInput
} from '../../../components/custom';
import InnSvg from '../../../assets/icons/Inn.svg';
import userSvg from '../../../assets/icons/User.svg';
import GeoSvg from '../../../assets/icons/Geo.svg';

import {useSelector} from 'react-redux';
import DatePicker from 'react-date-picker';
import {ModalAddress} from '../../../components/modals/ModalAddress';
import {ModalTypeUser} from '../../../components/modals/ModalTypeUser';
import {ModalSubTypeUser} from '../../../components/modals/ModalSubTypeUser';
import {ModalCategory} from '../../../components/modals/ModalCategory';
import { Request } from '../../../requests/request';

export const ViewUser = () => {
    const location = useLocation();
    const token = useSelector(state => state.auth.session.token);
    const navigate = useNavigate();

    const [name, setName] = React.useState(location.state.name?? '');
    const [surname, setSurname] = React.useState(location.state.surname?? '');
    const [midname, setMidname] = React.useState(location.state.midname?? '');
    const [innSelfIp, setInnSelfIp] = React.useState(location.state.inn_self_ip?? '');
    const [innOrgPerformer, setInnOrgPerformer] = React.useState(location.state.inn_org_performer?? '');
    const [innOrgClient, setInnOrgClient] = React.useState(location.state.inn_org_client?? '');
    const [kppOrgPerformer, setKppOrgPerformer] = React.useState(location.state.kpp_org_performer?? '');
    const [kppOrgClient, setKppOrgClient] = React.useState(location.state.kpp_org_client?? '');
    const [shortPerformer, setShortPerformer] = React.useState(location.state.short_name_org_performer?? '');
    const [shortClient, setShortClient] = React.useState(location.state.short_name_org_client?? '');
    const [tradeC, setTradeC] = React.useState(location.state.tradeMark_client?? '');
    const [tradeP, setTradeP] = React.useState(location.state.tradeMark_performer?? '');
    const [fullTimeP, setFullTimeP] = React.useState(location.state.interest_performer.full_time_work);
    const [oneTimeP, setOneTimeP] = React.useState(location.state.interest_performer.one_time_work);
    const [fullTimeC, setFullTimeC] = React.useState(location.state.interest_client.full_time_work);
    const [oneTimeC, setOneTimeC] = React.useState(location.state.interest_client.one_time_work);
    const [birthdate, setBirthdate] = React.useState(new Date(location.state.birthdate_phys_self)?? new Date());
    const [gender, setGender] = React.useState(location.state.gender?? 'Мужской');
    const [descrC, setDescrC] = React.useState(location.state.description_client?? '');
    const [descrP, setDescrP] = React.useState(location.state.description_performer?? '');

    const [addressC, setAddressC] = React.useState('');
    const [addressP, setAddressP] = React.useState('');
    const [geolocationC, setGeolocationC] = React.useState({ latitude: 0, longitude: 0 });
    const [geolocationP, setGeolocationP] = React.useState({ latitude: 0, longitude: 0 });
    const [modalAddressP, setModalAddressP] = React.useState(false);
    const [modalAddressC, setModalAddressC] = React.useState(false);
    const [modalTypeUser, setModalTypeUser] = React.useState(false);
    const [modalSubTypeUser, setModalSubTypeUser] = React.useState(false);

    const [typeUser, setTypeUser] = React.useState(location.state.typeUser?? 'Performer');
    const [subTypeUser, setSubTypeUser] = React.useState(location.state.typeUser === 'Performer'? location.state.subTypeUser_performer?? 'Физическое лицо': location.state.subTypeUser_client?? 'Физическое лицо');
    const [modalCategory, setModalCategory] = React.useState(false);
    const [category, setCategory] = React.useState(location.state.category?? []);
    const [displayMap, setDisplayMap] = React.useState(location.state.display_map_performer?? false);

    React.useEffect(() => {
        setSubTypeUser(typeUser === 'Performer'? location.state.subTypeUser_performer: location.state.subTypeUser_client);
    }, [typeUser]);

    return(
        <div className={styles.main}>
            {
                location.state.typeUser === 'Performer'?
                    location.state.avatar_performer !== ''?
                        <img 
                            className='w-[103px] h-[103px]' 
                            src={location.state.avatar_performer}
                        />:
                        <div className={styles.btnAvatar}>Нет фото</div>:
                    location.state.avatar_client !== ''?
                        <img 
                            className='w-[103px] h-[103px]' 
                            src={location.state.avatar_client}
                        />:
                        <div className={styles.btnAvatar}>Нет фото</div>
            }
            
            <div className='flex flex-col'>
                <Label text="Тип пользователя" />
                <Select value={typeUser === 'Performer'? 'Исполнитель': 'Клиент'} onChange={() => { setModalTypeUser(true); }} />
                <Select value={subTypeUser} onChange={() => { setModalSubTypeUser(true); }} />
                <Label text="Имя" />
                <Input 
                    value={name}
                    onChange={(event) => {setName(event.target.value)}}
                    placeholder="Имя"
                    image={userSvg}
                    type={'default'}
                />
                <Label text="Фамилия" />
                <Input 
                    value={surname}
                    onChange={(event) => {setSurname(event.target.value)}}
                    placeholder="Фамилия"
                    image={userSvg}
                    type={'default'}
                />
                <Label text="Отчество" />
                <Input 
                    value={midname}
                    onChange={(event) => {setMidname(event.target.value)}}
                    placeholder="Отчество"
                    image={userSvg}
                    type={'default'}
                />
                {
                    typeUser === 'Performer'?
                        subTypeUser === 'Самозанятый' || subTypeUser === 'ИП'?
                            <>
                                <Label text="ИНН" />
                                <Input 
                                    value={innSelfIp}
                                    onChange={(event) => {setInnSelfIp(event.target.value)}}
                                    placeholder="ИНН"
                                    image={InnSvg}
                                    type={'default'}
                                />
                            </>:
                            subTypeUser === 'Организация'?
                                <>
                                    <Label text="ИНН" />
                                    <Input 
                                        value={innOrgPerformer}
                                        onChange={(event) => {setInnOrgPerformer(event.target.value)}}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                        type={'default'}
                                    />
                                    <Label text="КПП" />
                                    <Input 
                                        value={kppOrgPerformer}
                                        onChange={(event) => {setKppOrgPerformer(event.target.value)}}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                        type={'default'}
                                    />
                                    <Label text="Юрид. сокращенное название" />
                                    <Input 
                                        value={shortPerformer}
                                        onChange={(event) => {setShortPerformer(event.target.value)}}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                        type={'default'}
                                    />
                                </>:
                                <></>:
                        subTypeUser === 'Самозанятый' || subTypeUser === 'ИП'?
                            <>
                                <Label text="ИНН" />
                                <Input 
                                    value={innSelfIp}
                                    onChange={(event) => {setInnSelfIp(event.target.value)}}
                                    placeholder="ИНН"
                                    image={InnSvg}
                                    type={'default'}
                                />
                            </>:
                            subTypeUser === 'Организация'?
                                <>
                                    <Label text="ИНН" />
                                    <Input 
                                        value={innOrgClient}
                                        onChange={(event) => {setInnOrgClient(event.target.value)}}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                        type={'default'}
                                    />
                                    <Label text="КПП" />
                                    <Input 
                                        value={kppOrgClient}
                                        onChange={(event) => {setKppOrgClient(event.target.value)}}
                                        placeholder="КПП"
                                        image={InnSvg}
                                        type={'default'}
                                    />
                                    <Label text="Юрид. сокращенное название" />
                                    <Input 
                                        value={shortClient}
                                        onChange={(event) => {setShortClient(event.target.value)}}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                        type={'default'}
                                    />
                                </>:
                                <></>
                }
                {
                    typeUser === 'Performer'?
                        <>
                            <Label text="Торговая марка" />
                            <Input 
                                value={tradeP}
                                onChange={(event) => {setTradeP(event.target.value)}}
                                placeholder="Торговая марка"
                                image={InnSvg}
                                type={'default'}
                            />
                        </>:
                        <>
                            <Label text="Торговая марка" />
                            <Input 
                                value={tradeC}
                                onChange={(event) => {setTradeC(event.target.value)}}
                                placeholder="Торговая марка"
                                image={InnSvg}
                                type={'default'}
                            />
                        </>
                }
                {
                    typeUser === 'Performer'?
                        <>
                            <Label text="Пользователя интересует" />
                            <Switch text="Постоянная работа" value={fullTimeP} onChange={(value) => { setFullTimeP(value); }} />
                            <Switch text="Разовая работа" value={oneTimeP} onChange={(value) => { setOneTimeP(value); }} />        
                        </>:
                        <>
                            <Label text="Пользователя интересует" />
                            <Switch text="Постоянная работа" value={fullTimeC} onChange={(value) => { setFullTimeC(value); }} />
                            <Switch text="Разовая работа" value={oneTimeC} onChange={(value) => { setOneTimeC(value); }} />
                        </>
                }
                {
                    typeUser === 'Performer'?
                        subTypeUser === 'Физическое лицо' || subTypeUser === 'ИП'?
                            <>
                                <Label text="Дата рождения" />
                                <DatePicker 
                                    className={styles.datePicker} 
                                    value={birthdate} 
                                    onChange={setBirthdate} 
                                    format={'dd.MM.yyyy'}
                                    locale={'ru-RU'}    
                                />
                               
                            </>:
                            <></>
                        :
                        subTypeUser === 'Физическое лицо' || subTypeUser === 'Самозанятый'?
                            <>
                                <Label text="Дата рождения" />
                                <DatePicker 
                                    className={styles.datePicker} 
                                    value={birthdate} 
                                    onChange={setBirthdate} 
                                    format={'dd.MM.yyyy'}
                                    locale={'ru-RU'}    
                                />
                            </>:
                            <></>
                }
                <Label text="Пол" />
                <Gender value={gender} onChange={(value) => { setGender(value); }} />
                <Label text="Краткое описание" />
                <MultiInput 
                    value={typeUser === 'Performer'? descrP: descrC}
                    onChange={(event) => { if(typeUser === 'Performer') { setDescrP(event.target.value); } else { setDescrC(event.target.value); } }}
                />
                <Label text="Сфера" />
                <Select value={'Сфера'} onChange={() => { setModalCategory(true); }} />
                <ViewCategories data={ category } />
                {
                    typeUser === 'Performer'?
                        <>
                            <DisplayMap value={displayMap} onChange={(value) => { setDisplayMap(value); }} />
                            <Label text="Местоположение для поиска рядом" />
                            <Select 
                                value={addressP}
                                onChange={() => { setModalAddressP(true); }}
                                limit={5}
                            />
                        </>:
                        <>
                            <Label text="Постоянный адрес" />
                            <Select 
                                value={addressC}
                                onChange={() => { setModalAddressC(true); }}
                            />
                            <ViewText 
                                text={location.state.address_client.address}
                                image={GeoSvg}
                            />
                        </>
                }
                <div className='flex flex-row items-center gap-[10px] mb-[16px]'>
                    <button 
                        className={styles.btnEdit}
                        onClick={async () => {
                            let data = {
                                phone: location.state.phone,
                                typeUser: typeUser,
                                subTypeUser_performer: typeUser === 'Performer'? subTypeUser: location.state.subTypeUser_performer,
                                subTypeUser_client: typeUser === 'Client'? subTypeUser: location.state.subTypeUser_client,
                                avatar_performer: location.state.avatar_performer,
                                avatar_client: location.state.avatar_client,
                                name: name,
                                surname: surname,
                                midname: midname,
                                inn_self_ip: innSelfIp,
                                inn_org_performer: innOrgPerformer,
                                inn_org_client: innOrgClient,
                                kpp_org_performer: kppOrgPerformer,
                                kpp_org_client: kppOrgClient,
                                short_name_org_performer: shortPerformer,
                                short_name_org_client: shortClient,
                                tradeMark_performer: tradeP,
                                tradeMark_client: tradeC,
                                interest_performer: {
                                    full_time_work: fullTimeP,
                                    one_time_work: oneTimeP
                                },
                                interest_client: {
                                    full_time_work: fullTimeC,
                                    one_time_work: fullTimeC
                                },
                                birthdate_phys_self: birthdate,
                                gender: gender,
                                description_performer: descrP,
                                description_client: descrC,
                                category: category,
                                display_map_performer: displayMap,
                                address_performer: {
                                    geolocation: geolocationP,
                                    address: addressP
                                },
                                address_client: {
                                    geolocation: geolocationC,
                                    address: addressC
                                },     
                                id: location.state._id                       
                            };
                            try {
                                const response = await Request(`/api/users/admin`, 'PUT', token, JSON.stringify({
                                    data: data
                                }));
                                if(response.status === 200) {
                                    navigate('/');
                                };
                            } catch(e) {
                                console.log('e', e);
                            }
                        }}
                    >Сохранить</button>
                    <button 
                        className='w-1/4 bg-red text-white font-medium h-[48px] rounded-[12px]'
                        onClick={async () => {
                            try {
                                const response = await Request(`/delete/user`, 'DELETE', token, JSON.stringify({
                                    id: location.state._id
                                }));
                                if(response.status === 200) {
                                    navigate('/')
                                };
                            } catch(e) {
                                console.log('e', e);
                            };
                        }}
                    >Удалить</button>
                </div>
            </div>
            {modalAddressP && <ModalAddress 
                onChange={(value) => {
                    setAddressP(value.address);
                    setGeolocationP(value.geolocation);
                    setModalAddressP(false);
                }}
                close={() => { setModalAddressP(false); }} 
                value={{
                    placement: geolocationP,
                    address: addressP
                }}
            />}
            {modalAddressC && <ModalAddress 
                onChange={(value) => {
                    setAddressC(value.address);
                    setGeolocationC(value.geolocation);
                    setModalAddressC(false);
                }}
                close={() => { setModalAddressC(false); }}
                value={{
                    placement: geolocationC,
                    address: addressC
                }}
            />}
            {
                modalTypeUser && <ModalTypeUser 
                    value={typeUser}
                    onChange={(value) => { setTypeUser(value); }}
                    close={() => { setModalTypeUser(false); }}
                />
            }
            {
                modalSubTypeUser && <ModalSubTypeUser 
                    value={subTypeUser}
                    onChange={(value) => { setSubTypeUser(value); }}
                    close={() => { setModalSubTypeUser(false); }}
                />
            }
            {
                modalCategory && <ModalCategory 
                    value={category}
                    onChangeValue={(value) => {
                        setCategory(value);
                    }}  
                    close={() => { setModalCategory(false); }}
                    limit={5}
                />
            }
        </div>
    );
};

const styles = {
    main: 'container mx-auto overflow-y-scroll',
    btnAvatar: 'w-[103px] h-[103px] bg-white mt-[20px] border-[#BFE9CB] border-[1px] rounded-[12px] mb-[16px] flex items-center justify-center text-[16px] font-regular',
    btnEdit: 'w-1/3 h-[48px] bg-[#14AF3F] rounded-[12px] text-white font-medium',
    datePicker: 'w-1/3 h-[48px] rounded-[12px] mb-[16px]'
};
import * as React from 'react';
import {Request} from '../../requests/request';

export const ModalViewComplaint = (props) => {
    const {
        item, close
    } = props;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    console.log('item compl', item);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            try {
                let response = Request(`/api/users/ids?ids=${[item.idUser, item.id]}`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setData(json.data.data);
                    }
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        } 
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>{item.typeComplaint}</p>
                <p className={styles.date}>{new Date(item.created).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                <p className={styles.label}>От:</p>
                <p className={styles.user}>{item.userFrom.name} {item.userFrom.surname}</p>
                <p className={styles.label}>Кому:</p>
                <p className={styles.user}>{item.userTo.name} {item.userTo.surname}</p>
                <p className={styles.descr}>{item.description}</p>
                <button
                    className={styles.btnClose}
                    onClick={() => {
                        close();
                    }}
                >Закрыть</button>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute modal top-0 left-0 flex items-center justify-center w-full h-full',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[16px] font-medium',
    label: 'text-grey-text mt-[5px] text-[14px] font-regular',
    date: 'mt-[5px] text-grey-text',
    user: 'mt-[5px] text-black text-[16px] font-regular',
    descr: 'my-[10px] text-[16px] font-regular',
    btnClose: 'px-[20px] py-[10px] bg-main rounded-[12px] text-white font-medium '
}
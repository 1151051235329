import * as React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {Login} from './pages/login/Login';
import {Users} from './pages/main/Users';
import {Vacations} from './pages/main/Vacations';
import {Category} from './pages/main/Category';
import {SubCategory} from './pages/main/SubCategory';
import {Cities} from './pages/main/Cities';
import {Regions} from './pages/main/Regions';
import {AdminsMod} from './pages/main/AdminsMod';
import {Profile} from './pages/main/Profile';
import { Complaints } from './pages/main/Complaints';
import { Nationality } from './pages/main/Nationality';
import { Logs } from './pages/main/Logs';

import {Chats} from './pages/main/users/Chats';
import {ViewChat} from './pages/main/users/ViewChat';
import {ViewUser} from './pages/main/users/ViewUser';

export const MainRoutes = () => {
    const signedIn = useSelector(state => state.auth.session.signedIn);

    // console.log('signIn', signedIn);
    return(
        <div className='w-full overflow-y-scroll'>
        <Routes>
            <Route path='/login' element={<Login />} />
            {/* <Route path='/' element={<Main />} /> */}
            <Route path='/'  element={signedIn? <Users />: <Navigate to={'/login'} replace />} />
            <Route path='/vacations' element={signedIn? <Vacations />: <Navigate to={'/login'} replace />} />
            <Route path='/category' element={signedIn? <Category />: <Navigate to={'/login'} replace />} />
            <Route path='/subcategory' element={signedIn? <SubCategory />: <Navigate to={'/login'} replace />} />
            <Route path='/cities' element={signedIn? <Cities />: <Navigate to={'/login'} replace />} />
            <Route path='/regions' element={signedIn? <Regions />: <Navigate to={'/login'} replace />} />
            <Route path='/admins' element={signedIn? <AdminsMod />: <Navigate to={'/login'} replace />} />
            <Route path='/complaints' element={signedIn? <Complaints />: <Navigate to={'/login'} replace />} />
            <Route path='/nationality' element={signedIn? <Nationality />: <Navigate to={'/login'} replace />} />
            <Route path='/profile' element={signedIn? <Profile />: <Navigate to={'/login'} replace />} />
            <Route path='/users/:id' element={signedIn? <ViewUser />: <Navigate to={'/login'} replace />} />
            <Route path='/users/:id/chats' element={signedIn? <Chats />: <Navigate to={'/login'} replace />}/>
            <Route path='/users/:id/chats/:idChat' element={signedIn? <ViewChat />: <Navigate to={'/login'} replace />} />
            <Route path='/logs' element={signedIn? <Logs />: <Navigate to={'/login'} replace />} />
            <Route path='*' element={<Navigate to={signedIn? '/Users': '/login'} replace />} />
        </Routes>
        </div>
    );
}
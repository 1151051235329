import * as React from 'react';
import { useSelector } from 'react-redux';
import { 
    Label, Select, ViewText, Switch, DisplayMap,
    ViewCategories, Input, Gender, MultiInput
} from '../../components/custom';
import InnSvg from '../../assets/icons/Inn.svg';
import userSvg from '../../assets/icons/User.svg';
import GeoSvg from '../../assets/icons/Geo.svg';

import DatePicker from 'react-date-picker';
import {ModalAddress} from '../../components/modals/ModalAddress';
import {ModalTypeUser} from '../../components/modals/ModalTypeUser';
import {ModalSubTypeUser} from '../../components/modals/ModalSubTypeUser';
import {ModalCategory} from '../../components/modals/ModalCategory';
import { Request } from '../../requests/request';

export const ModalAddUser = (props) => {
    const { token, setModalVisible, onSuccess } = props;

    const geolocationRedux = useSelector(state => state.users.geolocation);

    const [typeUser, setTypeUser] = React.useState('Performer');
    const [subTypeUser, setSubTypeUser] = React.useState('Физическое лицо');
    const [phone, setPhone] = React.useState('');
    const [name, setName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [midname, setMidname] = React.useState('');
    const [innSelfIp, setInnSelfIp] = React.useState('');
    const [innOrgP, setInnOrgP] = React.useState('');
    const [innOrgC, setInnOrgC] = React.useState('');
    const [kppP, setKppP] = React.useState('');
    const [kppC, setKppC] = React.useState('');
    const [shortP, setShortP] = React.useState('');
    const [shortC, setShortC] = React.useState('');
    const [tradeC, setTradeC] = React.useState('');
    const [tradeP, setTradeP] = React.useState('');
    const [fullTimeP, setFullTimeP] = React.useState(false);
    const [oneTimeP, setOneTimeP] = React.useState(false);
    const [birthdate, setBirthdate] = React.useState(new Date());
    const [gender, setGender] = React.useState('Мужской');
    const [descrP, setDescrP] = React.useState('');
    const [descrC, setDescrC] = React.useState('');
    const [category, setCategory] = React.useState([]);
    const [displayMap, setDisplayMap] = React.useState(false);
    const [addressP, setAddressP] = React.useState('');
    const [addressC, setAddressC] = React.useState('');
    const [geolocationP, setGeolocationP] = React.useState({latitude: 0, longitude: 0});
    const [geolocationC, setGeolocationC] = React.useState({latitude: 0, longitude: 0});

    const [modalTypeUser, setModalTypeUser] = React.useState(false);
    const [modalSubTypeUser, setModalSubTypeUser] = React.useState(false);
    const [modalCategory, setModalCategory] = React.useState(false);
    const [modalAddressP, setModalAddressP] = React.useState(false);
    const [modalAddressC, setModalAddressC] = React.useState(false);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Новый пользователь</p>
                <Label text="Тип пользователя" />
                <Select value={typeUser === 'Performer'? 'Исполнитель': 'Заказчик'} onChange={() => { setModalTypeUser(true); }} />
                <Select value={subTypeUser} onChange={() => { setModalSubTypeUser(true); }} />
                <Label text="Телефон" />
                <Input 
                    value={phone}
                    onChange={(event) => { setPhone(event.target.value); }}
                    placeholder="Телефон"
                    image={userSvg}
                />
                <Label text="Имя" />
                <Input 
                    value={name}
                    onChange={(event) => { setName(event.target.value); }}
                    placeholder="Имя"
                    image={userSvg}
                />
                <Label text="Фамилия" />
                <Input 
                    value={surname}
                    onChange={(event) => { setSurname(event.target.value); }}
                    placeholder="Фамилия"
                    image={userSvg}
                />
                <Label text="Отчество" />
                <Input 
                    value={midname}
                    onChange={(event) => { setMidname(event.target.value); }}
                    placeholder="Отчество"
                    image={userSvg}
                />
                {
                    typeUser === 'Performer'?
                        subTypeUser === 'Самозанятый' || subTypeUser === 'ИП'?
                            <>
                                <Label text="ИНН" />
                                <Input 
                                    value={innSelfIp}
                                    onChange={(text) => { setInnSelfIp(text); }}
                                    placeholder="ИНН"
                                    image={InnSvg}
                                />
                            </>:
                            subTypeUser === 'Организация'?
                                <>
                                    <Label text="ИНН" />
                                    <Input 
                                        value={innOrgP}
                                        onChange={(text) => { setInnOrgP(text); }}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                    />
                                    <Label text="КПП" />
                                    <Input 
                                        value={kppP}
                                        onChange={(text) => { setKppP(text); }}
                                        placeholder="КПП"
                                        image={InnSvg}
                                    />
                                    <Label text="Юрид. сокращенное название" />
                                    <Input 
                                        value={shortP}
                                        onChange={(text) => { setShortP(text); }}
                                        placeholder="Юрид. сокращеннон название"
                                        image={InnSvg}
                                    />
                                </>:
                                <></>:
                        subTypeUser === 'Самозанятый' || subTypeUser === 'ИП'?
                            <>
                                <Label text="ИНН" />
                                <Input 
                                    value={innSelfIp}
                                    onChange={(text) => {setInnSelfIp(text)}}
                                    placeholder="ИНН"
                                    image={InnSvg}
                                />
                            </>:
                            subTypeUser === 'Организация'?
                                <>
                                    <Label text="ИНН" />
                                    <Input 
                                        value={innOrgC}
                                        onChange={(text) => {setInnOrgC(text)}}
                                        placeholder="ИНН"
                                        image={InnSvg}
                                    />  
                                    <Label text="КПП" />
                                    <Input 
                                        value={kppC}
                                        onChange={(text) => {setKppC(text); }}
                                        placeholder="КПП"
                                        image={InnSvg}
                                    />
                                    <Label text="Юрид. сокращенное название" />
                                    <Input 
                                        value={shortC}
                                        onChange={(text) => { setShortC(text); }}
                                        placeholder="Юрид. сокращенное название"
                                        image={InnSvg}
                                    />
                                </>:
                                <></>
                }
                <Label text="Торговая марка" />
                {
                    typeUser === 'Performer'?
                        <Input 
                            value={tradeP}
                            onChange={(text) => { setTradeP(text); }}
                            placeholder="Торговая марка"
                            image={InnSvg}
                        />:
                        <Input 
                            value={tradeC}
                            onChange={(text) => { setTradeC(text); }}
                            placeholder="Торговая марка"
                            image={InnSvg}
                        />
                }
                {
                    typeUser === 'Performer'?
                        <>
                            <Label text="Пользователя интересует" />
                            <Switch value={fullTimeP} onChange={(value) => { setFullTimeP(value); }} text="Постоянная работа" />
                            <Switch value={oneTimeP} onChange={(value) => { setOneTimeP(value); }} text="Разовая работа" />        
                        </>:
                        <></>
                }
                {
                    typeUser === 'Performer'?
                        <>
                            <Label text="Введите дату рождения" />
                            <DatePicker 
                                className={styles.datePicker} 
                                value={birthdate} 
                                onChange={setBirthdate} 
                                format={'dd.MM.yyyy'}
                                locale={'ru-RU'}    
                            />
                            <Label text="Пол пользователя" />
                            <Gender value={gender} onChange={(value) => { setGender(value); }} />
                        </>:
                        <>
                            <Label text="Введите дату рождения" />
                            <DatePicker 
                                className={styles.datePicker} 
                                value={birthdate} 
                                onChange={setBirthdate} 
                                format={'dd.MM.yyyy'}
                                locale={'ru-RU'}    
                            />
                        </>
                }
                {
                    typeUser === 'Performer'?
                        <>
                            <Label text="Краткое описание" />
                            <MultiInput 
                                value={descrP}
                                onChange={(event) => { setDescrP(event.target.value); }}
                                placeholder="Опыт работы, навыки, образование, обращение к заказчику, реклама или примерный прайс"
                            />
                        </>:
                        <>
                            <Label text="Краткое описание" />
                            <MultiInput 
                                value={descrC}
                                onChange={(event) => { setDescrC(event.target.value); }}
                                placeholder="Опыт работы, навыки, образование, обращение к заказчику, реклама или примерный прайс"
                            />
                        </>
                }
                {
                    typeUser === 'Performer'?
                        <>
                            <Label text="Сфера" />
                            <Select value={'Категория'} onChange={() => { setModalCategory(true); }} />
                            <ViewCategories data={category} />
                            <DisplayMap text="Отображать на карте" value={displayMap} onChange={(value) => { setDisplayMap(value); }} />
                            {
                                displayMap?
                                    <>
                                        <Label text="Ваше местоположение для поиска рядом" />
                                        <Select value={addressP} onChange={() => { setModalAddressP(true); }} />
                                    </>:
                                    <></>
                            }
                        </>:
                        <>
                            <Label text="Постоянный адрес" />
                            <Select value={addressC} onChange={() => { setModalAddressC(true); }} />
                        </>
                }
                <div className={styles.divBtn}>
                    <button
                        onClick={() => { 
                            setModalVisible(false);
                        }}
                        className={styles.btnCancel}
                    >Закрыть</button>
                    <button
                        onClick={async () => {
                            try {
                                let data = {
                                    phone: phone,
                                    typeUser: typeUser,
                                    subTypeUser_performer: typeUser === 'Performer'? subTypeUser: 'Физическое лицо',
                                    subTypeUser_client: typeUser === 'Client'? subTypeUser: 'Физическое лицо',
                                    avatar_performer: '',
                                    avatar_client: '',
                                    name: name,
                                    surname: surname,
                                    midname: midname,
                                    inn_self_ip: innSelfIp,
                                    inn_org_performer: innOrgP,
                                    inn_org_client: innOrgC,
                                    kpp_org_performer: kppP,
                                    kpp_org_client: kppC,
                                    short_name_org_performer: shortP,
                                    short_name_org_client: shortC,
                                    tradeMark_performer: tradeP,
                                    tradeMark_client: tradeC,
                                    interest_performer: {
                                        full_time_work: fullTimeP,
                                        one_time_work: oneTimeP
                                    },
                                    interest_client: {
                                        full_time_work: false,
                                        one_time_work: false
                                    },
                                    birthdate_phys_self: birthdate,
                                    gender: gender,
                                    description_performer: descrP,
                                    description_client: descrC,
                                    category: category,
                                    display_map_performer: displayMap,
                                    address_performer: {
                                        geoloction: geolocationP,
                                        address: addressP
                                    },
                                    address_client: {
                                        geolocation: geolocationC,
                                        address: addressC
                                    }
                                };
                                const response = await Request(`/post/userByAdmin`, 'POST', token, JSON.stringify({
                                    data: data
                                }));
                                if(response.status === 200) {
                                    onSuccess();
                                }
                            } catch(e) {
                                console.log('e', e);
                            };
                        }}
                        className={styles.btnCreate}
                    >Создать</button>
                </div>
            </div>
            {
                modalTypeUser && <ModalTypeUser 
                    value={typeUser}
                    onChange={(value) => { setTypeUser(value); }}
                    close={() => { setModalTypeUser(false); }}
                />
            }
            {
                modalSubTypeUser && <ModalSubTypeUser 
                    value={subTypeUser}
                    onChange={(value) => { setSubTypeUser(value); }}
                    close={() => { setModalSubTypeUser(false); }}
                />
            }
            {
                modalCategory && <ModalCategory 
                    value={category}
                    onChangeValue={(value) => { setCategory(value); }}
                    close={() => { setModalCategory(false); }} 
                    limit={5}
                />
            }
            {
                modalAddressP && <ModalAddress 
                    onChange={(value) => {
                        setAddressP(value.address);
                        setGeolocationP(value.geolocation);
                        setModalAddressP(false);
                    }}
                    close={() => { setModalAddressP(false); }}
                    value={{
                        placement: geolocationP,
                        address: addressP
                    }}
                />
            }
            {
                modalAddressC && <ModalAddress 
                    onChange={(value) => {
                        setAddressC(value.address);
                        setGeolocationC(value.geolocation);
                        setModalAddressC(false);
                    }}
                    close={() => { setModalAddressC(false); }}
                    value={{
                        placement: geolocationC,
                        address: addressC
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: 'absolute modal w-full h-full left-0 top-0 flex flex-col items-center justify-center',
    div: 'bg-white p-[20px] rounded-[12px] flex flex-col w-2/3 h-3/4 overflow-y-scroll shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)]',
    title: 'font-medium text-[20px] mb-[20px]',
    input: 'border-main border-[1px] rounded-[12px] mt-[5px] py-[10px] text-[16px] font-regular outline-none px-[12px]',
    label: 'mt-[10px] font-regular text-[14px] text-grey-text',
    btnBirth: 'border-main border-[1px] rounded-[12px] py-[10px] mt-[10px] font-regular text-[16px] px-[12px] py-[20px] outline-none',
    divTypeUser: 'flex flex-row items-center mt-[10px]',
    btnTypeUser: 'px-[20px] mr-[20px] bg-green py-[5px] rounded-[12px] text-[16px] font-regular',
    btnSubTypeUser: 'px-[20px] bg-green py-[5px] rounded-[12px] text-[16px] font-regular',
    divBtn: 'flex flex-row items-center gap-[10px]',
    btnCancel: 'w-1/4 bg-grey h-[48px] rounded-[12px] text-black font-medium',
    btnCreate: 'w-1/4 bg-main h-[48px] rounded-[12px] text-white font-medium',
    datePicker: 'w-1/3 h-[48px] rounded-[12px] mb-[16px]'
}
import * as React from 'react';
import { Request } from '../requests/request';

export const RenderComplaints = (props) => {
    const {
        item, index, onView, onSuccess, token, role
    } = props;

    return(
        <div 
            key={index.toString()} 
            className={styles.main(index)} 
        >
            <div 
                className='flex flex-col w-full'
                onClick={() => {
                    onView();
                }}        
            >
                <p className={styles.title}>{item.typeComplaint}</p>
                <p className={styles.date}>{new Date(item.created).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                <p className={styles.status(item.visible)}>{item.visible? 'Новый': 'Просмотрено'}</p>
            </div>
            <div className='flex flex-col items-end'>
                {
                    item.visible?
                        <button
                            className={styles.btnSeen}
                            onClick={() => {
                                try {
                                    let response = Request(`/api/complaints`, 'put', token, JSON.stringify({
                                        data: {
                                            id: item._id,
                                            ...item,
                                            visible: false
                                        }
                                        
                                    }));

                                    response.then((json) => {
                                        if(json.status === 200 ) {
                                            onSuccess();
                                        }
                                    })
                                } catch(e) {
                                    console.log('e', e);
                                };
                            }}
                        >Пометить как прочитан</button>:
                        <></>
                }
                {
                    role === 'admin'?
                        <button
                            className={styles.btnDelete}
                            onClick={() => {
                                try {
                                    let response = Request('/api/complaints', 'delete', token, JSON.stringify({
                                        id: item._id
                                    }));
                                    response.then((json) => {
                                        if(json.status === 200) {
                                            onSuccess();
                                        }
                                        
                                    });
                                } catch(e) {
                                    console.log('e', e);
                                }
                            }}
                        >Удалить</button>:
                        <></>
                }
                
            </div>
            
        </div>
    );
};

const styles = {
    main: (index) => `${index === 0? 'mt-[20px]': ''} mb-[20px] bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] flex flex-row items-center justify-between`,
    title: 'text-[18px] font-medium',
    date: 'text-[16px] font-regular mt-[5px] text-grey-text',
    status: (status) =>  `text-[16px] font-medium mt-[5px] line-clamp-1 ${status? 'text-main ': 'text-grey-text '}`,
    btnSeen: 'px-[20px] py-[5px] text-black font-medium line-clamp-1 whitespace-nowrap',
    btnDelete: 'px-[20px] py-[5px] text-red font-medium'
}
import * as React from 'react';
import { Request } from '../../requests/request';
import { CheckBox } from '../CheckBox';

export const ModalEditNationality = (props) => {
    const {onClose, item, token, onSuccess} = props;

    const [data, setData] = React.useState(item);
    const [loading, setLoading] = React.useState(false);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Изменить гражданство</p>
                <p className={styles.label}>Имя</p>
                <input 
                    className={styles.input}
                    value={data.name}
                    onChange={(event) => {
                        setData({...data, name: event.target.value});
                    }}
                />
                <CheckBox 
                    value={data.visible}
                    onChange={(value) => {
                        setData({...data, visible: value})
                    }}
                    text="Статус активности"
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            onClose()
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnSave}
                        onClick={() => {
                            try {
                                setLoading(true)
                                let response = Request(`/api/nationalities`, 'put', token, JSON.stringify({
                                    data: {
                                        ...data,
                                        id: item._id
                                    }
                                }))
                                response.then((json) => {
                                    if(json.status === 200) {
                                        onSuccess();
                                    }
                                    setLoading(false);
                                })
                            } catch(e) {
                                console.log('e', e);
                                setLoading(false);
                            };
                        }}
                    >Изменить</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute modal top-0 left-0 flex items-center justify-center w-full h-full',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium',
    input: 'border-main border-[1px] mt-[10px] w-3/4 px-[20px] py-[10px] rounded-[12px] outline-none text-[16px]',
    label: 'text-[16px] font-regular text-grey-text mt-[20px]',
    divBtns: 'flex flex-row items-center justify-between mt-[20px]',
    btnClose: 'px-[20px] py-[10px] bg-grey text-black font-medium text-[16px] rounded-[12px]',
    btnSave: 'px-[20px] py-[10px] bg-main text-white font-medium text-[16px] rounded-[12px]'
}
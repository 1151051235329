import * as React from 'react';
import { YMaps, Map, Placemark, SearchControl, ZoomControl, FullscreenControl, useYMaps } from '@pbe/react-yandex-maps';
import { useSelector } from 'react-redux';
import { ModalCity } from './ModalCity';

const searchAddress = async (city, address, setLoading, setDataAddress, setSearch) => {
    try {
        setLoading(true);
        const response = await fetch(`https://geocode-maps.yandex.ru/1.x?geocode=${city},${address}&kind=house&apikey=33dcae0c-b4e0-494f-8f42-43a0cf50184e&format=json&lang=ru_RU`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
        let statusCode = response.status;
        let json = await response.json();
        console.log('json address', statusCode, json);
        if(statusCode === 200) {
            setDataAddress(json?.response?.GeoObjectCollection.featureMember)
        };
        setLoading(false);
    } catch(e) {
        setLoading(false);
    }
    
}

export const ModalAddress = (props) => {
    const {
        onChange, close, value
    } = props;
    
    const cityRedux = useSelector(state => state.users.city);
    const geolocationRedux = useSelector(state => state.users.geolocation);
    const [state, setState] = React.useState(value? {center: [value?.placement.latitude, value?.placement.longitude], zoom: 15}: undefined);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [dataAddress, setDataAddress] = React.useState([]);
    const [resultAddress, setResultAddress] = React.useState(value?.address? value.address: '');

    const refMain = React.useRef(null);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Прикрепление адреса</p>
                <p className='mt-[10px] font-regular text-grey-text'>Описание:</p>
                <p className='mt-[5px] font-regular text-[14px] text-grey-text'>В поле "введите адрес" пишете нужный вам адрес, если в списке нет нужного адреса, вам необходимо, на карте нажать на подходящее здание и в поле "Конечный адрес" написать адрес вручную.</p>
                <p className={styles.label}>Город:</p>
                <div className='mt-[10px] flex flex-row items-center justify-between'>
                    <button 
                        className='w-1/4 bg-main h-[41px] mr-[10px] rounded-[10px] text-white font-medium'
                        onClick={() => { setModalVisible(true); }}
                    >{cityRedux}</button>
                    <input 
                        value={search}
                        onChange={(event) => { setSearch(event.target.value); }}
                        className={styles.input}
                        placeholder="Введите адрес"
                    />
                    <button
                        className={styles.btnSearch}
                        onClick={() => {
                            searchAddress(cityRedux, search, setLoading, setDataAddress, setSearch);
                        }}
                    >Поиск</button>
                </div>
                <p className='mt-[10px] text-grey-text font-regular'>Если здание</p>
                <div className='w-[400px] h-[400px] mt-[10px]'>
                    <YMaps
                        query={{
                            lang: 'ru_RU'
                        }}
                    >
                        <Map 
                            instanceRef={refMain}
                            defaultState={{center: [geolocationRedux?.latitude, geolocationRedux?.longitude], zoom: 15}} 
                            state={state}
                            width={400} height={400} 
                            onClick={(event) => {
                                console.log('onClick', event._sourceEvent.originalEvent.coords);
                                setState({
                                    center: event._sourceEvent.originalEvent.coords,
                                    zoom: 15
                                })
                            }}
                            click={(event) => {
                                console.log('click', event);
                            }}
                        >
                            <ZoomControl options={{float: 'right'}} />
                            <FullscreenControl />
                            <Placemark 
                                defaultGeometry={[state?.center[0], state?.center[1]]} 
                                geometry={state?.center}
                            />
                        </Map>
                    </YMaps>
                </div>
                <div className='h-[200px] overflow-y-scroll bg-grey mt-[20px] grid grid-cols-1 gap-[10px]'>
                {
                    dataAddress.length > 0?
                        dataAddress.map((item, index) => 
                            <button
                                key={index.toString()}
                                className='bg-border-green py-[10px] flex flex-row items-center px-[20px]'
                                onClick={() => {
                                    let point = item.GeoObject.Point.pos.split(' '); 
                                    setState({center: [Number(point[1]), Number(point[0])], zoom: 15}); 
                                    setResultAddress(item.GeoObject.description + ', ' + item.GeoObject.name);
                                }}
                            >{item.GeoObject.description}, {item.GeoObject.name}</button>
                        ):
                        <div className='h-full flex flex-col items-center justify-center text-[16px] text-grey-text font-medium'>Адрес не найден</div>
                }
                </div>
                <p className='mt-[10px] font-regular text-grey-text'>Конечный адрес:<label className='text-red text-[14px]'>(Если адрес не найден в списке указывайте здесь вручную)</label></p>
                <input 
                    value={resultAddress}
                    onChange={(event) => { setResultAddress(event.target.value); }}
                    className={styles.input + ' mt-[10px]'}
                    placeholder="Конечный адрес"
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { close(); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnApply}
                        onClick={() => {
                            onChange({
                                address: resultAddress,
                                geolocation: {
                                    latitude: state?.center[0],
                                    longitude: state?.center[1]
                                }
                            })
                        }}
                    >Применить</button>
                </div>
            </div>
            {
                modalVisible && <ModalCity 
                    setModalVisible={setModalVisible}
                />
            }
        </div>
    );
};

const styles = {
    main: 'absolute modal top-0 left-0 w-full h-full flex items-center justify-center',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-2/5',
    title: 'text-[20px] font-medium text-black',
    divBtns: 'flex flex-row items-center justify-between mt-[20px]',
    btnClose: 'bg-grey px-[20px] text-[16px] font-medium py-[9.5px] w-[150px] rounded-[12px]',
    btnApply: 'bg-main px-[20px] text-[16px] text-white font-medium py-[9.5px] w-[150px] rounded-[12px]',
    label: 'mt-[10px] text-[16px] font-regular text-grey-text',
    input: 'border-main outline-main border-[1px] rounded-[12px] px-[20px] py-[9.5px] text-[16px] font-regular text-black w-3/4 mr-[10px]',
    btnSearch: 'w-1/3 h-[41px] px-[20px] bg-main text-white text-[16px] font-medium rounded-[12px]'
}
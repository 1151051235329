import * as React from 'react';
import {Request} from '../../requests/request';
import { CheckBox } from '../CheckBox';
import { CustomSelect, CustomSelect2 } from '../CustomSelect';

export const ModalEditCities = (props) => {
    const {
        close, onSuccess, token, item
    } = props;

    const [data, setData] = React.useState(item);
    const [regions, setRegions] = React.useState([]);
    const [region, setRegion] = React.useState({
        name: item.region? {value: item.region, label: item.region}: '',
        id: null
    });
    const [loading, setLoading] = React.useState(false);
    console.log('item', item);

    const refRepeat = React.useRef(false);
    
    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            try {
                let response = Request(`/get/regions`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setRegions(json.data);
                        setLoading(false);
                    }
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            }
            
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Изменить город</p>
                <p className={styles.label}>Название</p>
                <input 
                    className={styles.input}
                    value={data.name}
                    onChange={(event) => {
                        setData({
                            ...data,
                            name: event.target.value
                        });
                    }}
                />
                <p className={styles.label}>Регион</p>
                <div className='mt-[10px]'>
                    <CustomSelect2
                        data={regions.map((item, index) => {return {value: item.name, label: item.name}})}
                        value={region.name}
                        onChange={(value) => {
                            setRegion({
                                name: value.value,
                                id: value._id
                            })
                        }}
                    />
                </div>
                <p className={styles.label}>Активность</p>
                <CheckBox 
                    value={data.visible}
                    onChange={(value) => {
                        setData({
                            ...data,
                            visible: value
                        })
                    }}
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { close(); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnSave}
                        onClick={() => { 
                            try {
                                let response = Request(`/put/city`, 'put', token, JSON.stringify({
                                    data: {
                                        id: item._id,
                                        ...data,
                                        region: typeof region.name === 'object'? region.name.value: region.name
                                    }   
                                }));
                                response.then((json) => {
                                    if(json.status === 200 && json.data.status === 'success') {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                            }
                        }}
                    >Изменить</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: "absolute modal top-0 left-0 flex items-center justify-center w-full h-full",
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium',
    label: 'text-[16px] text-grey-text font-regular mt-[10px]',
    input: 'border-main border-[1px] mt-[10px] font-regular text-[16px] px-[12px] py-[10px] rounded-[12px] w-full outline-none',
    divBtns: 'mt-[20px] flex flex-row items-center justify-between',
    btnClose: 'bg-grey px-[20px] py-[10px] rounded-[12px] text-black font-medium',
    btnSave: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium'
}
import * as React from 'react';

export const ModalTypeUser = (props) => {
    const {value, onChange, close} = props;
    const [data, setData] = React.useState(value);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Выберите</p>
                <button
                    className={styles.btn + ` ${data === 'Performer'? 'bg-main text-white': 'bg-white text-black'}`}
                    onClick={() => { setData('Performer'); }}
                >Исполнитель</button>
                <button
                    className={styles.btn + ` ${data === 'Client'? 'bg-main text-white': 'bg-white text-black'}`}
                    onClick={() => { setData('Client') }}
                >Клиент</button>
                <div className={styles.divBottom}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            close();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnChoose}
                        onClick={() => {
                            onChange(data);
                            close();
                        }}
                    >Выбрать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute top-[0px] left-0 flex items-center justify-center w-full ',
    div: 'shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] top-[50px] bg-white p-[20px] overflow-y-scroll rounded-[12px] w-[50%] flex flex-col',
    title: 'text-[20px] font-medium mb-[20px]',
    btn: 'h-[48px] w-1/3 font-regular rounded-[12px]',
    divBottom: 'flex flex-row gap-[10px] mt-[20px]',
    btnClose: 'h-[48px] w-1/4 bg-grey rounded-[12px] text-black font-medium',
    btnChoose: 'h-[48px] w-1/4 bg-main rounded-[12px] text-white font-medium'
};
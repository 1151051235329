import * as React from 'react';
import { Request } from '../../requests/request';
import { useSelector } from 'react-redux';
import { RenderLog } from '../../components/RenderLog';
import { ModalLog } from '../../components/modals/ModalLog';

export const Logs = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const token = useSelector(state => state.auth.user.token);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [modalValue, setModalValue] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        const response = Request('/api/logs', 'get', token, null);
        response.then((json) => {
            if(json.status === 200) {
                setData(json.data.data);
            };
            setLoading(false);
        }).catch((e) => { setLoading(false); });
    }, []);

    return(
        <div className='bg-white w-full h-full overflow-y-scroll'>
            <p className='mx-[20px] mt-[20px] mb-[20px] text-[20px] font-medium'>Логи <label className='text-main'>Найдено {data.length}</label></p>
            <div>
            {
                data.sort(function(a,b) { 
                    if(new Date(a.created) > new Date(b.created)) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).map((item, index) => 
                    <RenderLog 
                        item={item}
                        index={index}
                        setModalVisible={setModalVisible}
                        setModalValue={setModalValue}
                    />
                )
            }
            </div>
            {
                modalVisible && <ModalLog 
                    setModalVisible={setModalVisible}
                    value={modalValue}
                    onSuccessDelete={() => {
                        setModalVisible(false);
                        const response = Request('/api/logs', 'get', token, null);
                        response.then((json) => {
                            if(json.status === 200) {
                                setData(json.data.data);
                            };
                            setLoading(false);
                        }).catch((e) => { setLoading(false); });
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: ''
}
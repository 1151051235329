import * as React from 'react';
import {generate, validate} from '@wcj/generate-password';
import { CustomSelect, CustomSelect2 } from '../CustomSelect';
import { Request } from '../../requests/request';

export const ModalAddAdmin = (props) => {
    const {
        close, token, onSuccess
    } = props;

    const [data, setData] = React.useState({
        name: '',
        surname: '',
        midname: '',
        nickname: '',
        role: '',
        phone: '',
        email: '',
        password: ''
    })

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Новый Администратор/Модератор</p>
                <p className={styles.label}>Имя</p>
                <input 
                    className={styles.input}
                    value={data.name}
                    onChange={(event) => { setData({...data, name: event.target.value}); }}
                />
                <p className={styles.label}>Фамилия</p>
                <input 
                    className={styles.input}
                    value={data.surname}
                    onChange={(event) => { setData({...data, surname: event.target.value}); }}
                />
                <p className={styles.label}>Отчество</p>
                <input 
                    className={styles.input}
                    value={data.midname}
                    onChange={(event) => { setData({...data, midname: event.target.value}); }}
                />
                <p className={styles.label}>Роль</p>
                <CustomSelect2
                    data={[{value: 'Администратор', label: 'Администратор'}, {value: 'Модератор', label: 'Модератор'}]}
                    value={data.role}
                    onChange={(value) => {
                        setData({...data, role: value.value === 'Администратор'? 'admin': 'moderator'})
                        //console.log('onChage', value);
                    }}
                />
                <p className={styles.label}>email</p>
                <input 
                    className={styles.input}
                    value={data.email}
                    type={'email'}
                    onChange={(event) => { setData({...data, email: event.target.value}); }}
                />
                <p className={styles.label}>Пароль</p>
                <div className='flex flex-row'>
                    <input 
                        className={styles.input}
                        value={data.password}
                        onChange={(event) => { setData({...data, password: event.target.value}); }}
                    />
                    <button
                        className={styles.btnGenerate}
                        onClick={() => {
                            setData({
                                ...data,
                                password: generate()
                            });
                        }}
                    >Сгенерировать</button>
                </div>
                

                <div className={styles.divBnts}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { close(); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnAdd}
                        onClick={() => {
                            try {
                                let response = Request(`/post/admin`, 'post', token, JSON.stringify({
                                    data: data
                                }));
    
                                response.then((json) => {
                                    if(json.status === 200) {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                            }
                            
                        }}
                    >Добавить</button>
                </div>
            </div>
            
        </div>
    );
};

const styles = {
    main: 'absolute top-[0px] left-0 flex items-center justify-center w-full ',
    div: 'shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] top-[50px] bg-white p-[20px] overflow-y-scroll rounded-[12px] w-[50%]',
    title: 'font-medium text-[20px]',
    label: 'text-[14px] text-grey-text mt-[10px] font-regular',
    input: 'outline-none border-main border-[1px] mt-[10px] rounded-[12px] px-[12px] py-[10px] font-regular w-3/5',
    divBnts: 'flex flex-row items-center justify-between mt-[20px]',
    btnClose: 'px-[20px] bg-grey text-black py-[10px] rounded-[12px] font-medium',
    btnAdd: 'px-[20px] bg-main text-white py-[10px] rounded-[12px] font-medium',
    btnGenerate: 'ml-[20px] bg-grey mt-[10px] px-[20px] rounded-[12px] font-medium'
}
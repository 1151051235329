import * as React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {PostData} from '../../requests/post';
import { Request } from '../../requests/request';
import {onSignInSuccess} from '../../redux/auth/sessionSlice';
import {setUser} from '../../redux/auth/userSlice';

export const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    return(
        <div className={styles.main}>
            <div className='bg-white py-[20px] px-[40px] rounded-[12px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)]'>
                <p className={styles.title}>Авторизация</p>
                <p className={'mt-[20px] ' + styles.label}>Email</p>
                <input 
                    className={styles.input}
                    value={email}
                    type={'email'}
                    onChange={(event) => { setEmail(event.target.value); }}
                />
                <p className={styles.label} >Пароль</p>
                <input 
                    className={styles.input}
                    value={password}
                    type={'password'}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                />
                <button
                    className={styles.btn + `${email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) && password.length > 0? 'bg-main text-white border-transparent': 'bg-grey text-black border-[#DCDCDC]'}`}
                    disabled={
                        email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) && password.length > 0? false: true
                    }
                    onClick={async () => {
                        if(email.match(/\S+@\S+\.\S+/)) {
                            setLoading(true);
                            const response = await Request('/post/admin/sign', 'post', null, JSON.stringify({
                                email: email,
                                password: password
                            }));
                            if(response.status === 200) {
                                console.log('res', response);
                                dispatch(onSignInSuccess(response.data.token));
                                    dispatch(setUser(response.data.data));
                                    setLoading(false);
                                    navigate('/');
                            } else {
                                setLoading(false);
                            }
                            // PostData(`/post/admin/sign`, null, JSON.stringify({
                            //     email: email,
                            //     password: password
                            // }), (json, statusCode) => {
                            //     console.log('json', json);
                            //     console.log('statusCode',statusCode);
                            //     if(statusCode === 200 && json.token) {
                                    
                            //     } else {setLoading(false); }

                            // }, (e) => {
                            //     console.log('e', e);
                            //     setLoading(false);
                            // })
                        }
                    }}
                >Войти</button>
            </div>
        </div>
    );
};

const styles = {
    main: 'container mx-auto flex flex-1 flex-col bg-white items-center justify-center',
    title: "text-[39px] font-semi-bold text-black",
    label: 'mt-[20px] text-[14px] font-regular',
    input: "py-[10px] border-grey border-[1px] w-full mt-[10px] px-[12px] rounded-[12px] text-black font-regular outline-main",
    btn: 'w-full mt-[30px] py-[10px] rounded-[12px] text-[16px] font-medium '
}
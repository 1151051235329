import * as React from 'react';
import { CustomSelect, CustomSelect2 } from '../CustomSelect';
import {generate} from "@wcj/generate-password";
import useCopy from 'use-copy';
import { Request } from '../../requests/request';

export const ModalEditAdmin = (props) => {
    const {
        close, onSuccess, token, item
    } = props;

    const [data, setData] = React.useState(item);
    const [password, setPassword] = React.useState('');
    const [copied, copy, steCopied] = useCopy(password);
    const [dropped, setDropped] = React.useState(false);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <div className='flex flex-row items-start justify-between'>
                    <p className={styles.title}>Изменить пользователя</p>
                    <button
                        className={styles.btnDelete}
                        onClick={() => {
                            try {
                                let response = Request(`/delete/admin`, 'delete', token, JSON.stringify({
                                    id: item._id
                                }));
                                response.then((json) => {
                                    if(json.status === 200) {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                            }
                        }}
                    >Удалить</button>
                </div>
                <p className={styles.p}>Имя</p>
                <input 
                    className={styles.input}
                    value={data.name}
                    onChange={(event) => { setData({...data, name: event.target.value}); }}
                />
                <p className={styles.p}>Фамилия</p>
                <input 
                    className={styles.input}
                    value={data.surname}
                    onChange={(event) => { setData({...data, surname: event.target.value}); }}
                />
                <p className={styles.p}>Отчество</p>
                <input 
                    className={styles.input}
                    value={data.midname}
                    onChange={(event) => { setData({...data, midname: event.target.value}); }}
                />
                <p className={styles.p}>Электронная почта</p>
                <input 
                    className={styles.input}
                    value={data.email}
                    onChange={(event) => { setData({...data, email: event.target.value}); }}
                />
                <p className={styles.p}>Роль</p>
                <div className='mt-[10px]'>
                    <CustomSelect2
                        data={[{value: "Администратор", label: 'Администратор'}, {value: "Модератор", label: 'Модератор'}]}
                        value={data.role}
                        onChange={(value) => { setData({...data, role: value.value === 'Администратор'? 'admin': 'moderator'}); }}
                        className1=''
                    />
                </div>
                <div className='flex flex-row items-center mt-[10px]'>
                    <button
                        className={styles.btnDropPassword}
                        onClick={() => {
                            setPassword(generate());
                            setDropped(true);
                        }}
                    >Сбросить пароль</button>
                    {
                        dropped? 
                            <><p 
                                className={styles.droppedPass}
                                onClick={(event) => { copy(); setTimeout(() => {
                                    steCopied(false);
                                }, 3000); }}    
                            >{password}</p>{copied?<p className={styles.pCopy}>Скопирован</p>:<></>}</>:<></>
                    }
                </div>
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { close(); }}
                    >Закрыть</button>
                    <button
                        className={styles.btnSave}
                        onClick={() => {
                            try {
                                const response = Request(`/put/admin`, 'put', token, JSON.stringify({
                                    data: data,
                                    id: data._id
                                }));
    
                                response.then((json) => {
                                    if(json.status === 200) {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                                
                            }
                            
                        }}
                    >Сохранить</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute top-0 left-0 flex items-center justify-center w-full h-full',
    div: 'bg-white p-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] rounded-[12px] w-[50%] h-3/4 overflow-y-scroll',
    title: 'text-[20px] font-medium text-black',
    divBtns: 'mt-[20px] flex flex-row items-center justify-between',
    btnClose: 'bg-grey px-[20px] py-[10px] rounded-[12px] text-black font-medium',
    btnSave: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium',
    p: 'mt-[10px] text-[14px] font-regular text-grey-text',
    input: 'border-main border-[1px] mt-[10px] px-[12px] py-[10px] rounded-[12px] text-[16px] font-regular w-3/4',
    btnDropPassword: 'bg-grey px-[20px] py-[10px] rounded-[12px] text-black font-regular',
    droppedPass: 'border-main border-[1px] rounded-[12px] px-[20px] py-[10px] w-fit mx-[10px]',
    pCopy: 'text-main font-medium',
    btnDelete: 'text-red font-medium px-[20px] py-[10px]'
}
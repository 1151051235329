import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    users: [],
    city: 'Якутск',
    geolocation: {
        latitude: 62.027756,
        longitude: 129.72954
    }
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsersRedux: (state, action) => {
            state.users = action.payload;
        },
        setCityUserSlice: (state, action) => {
            state.city = action.payload;
        },
        setGeolocationUserSlice: (state, action) => {
            state.geolocation = action.payload;
        }
    }
});

export const {setUsersRedux, setCityUserSlice, setGeolocationUserSlice} = usersSlice.actions;

export default usersSlice.reducer;
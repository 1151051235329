import * as React from 'react';
import { CheckBox } from '../CheckBox';
import { Request } from '../../requests/request';

export const ModalAddRegions = (props) => {
    const {
        token, close, onSuccess
    } = props;

    const [name, setName] = React.useState('');
    const [visible, setVisible] = React.useState(false);
    const [code, setCode] = React.useState(0);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Добавление региона</p>
                <p className={styles.label}>Название</p>
                <input 
                    className={styles.input}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
                <p className={styles.label}>Код</p>
                <input 
                    className={styles.input}
                    value={code}
                    type={'number'}
                    onChange={(event) => {setCode(event.target.value); }}
                />
                <CheckBox 
                    value={visible}
                    onChange={(value) => setVisible(value)}
                    text="Показать"
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            close();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnCreate}
                        onClick={() => {
                            if(name.length > 0) {
                                try {
                                    const response = Request(`/post/region`, 'post', token, JSON.stringify({
                                        data: {
                                            name: name,
                                            code: code,
                                            visible: visible
                                        }
                                    }));
    
                                    response.then((json) => {
                                        if(json.status === 200 && json.data.status === 'success') {
                                            onSuccess();
                                        }
                                    })
                                } catch(e) {
                                    console.log('e', e);
                                };
                            } else {
                                alert('Заполните название региона');
                            };
                        }}
                    >Создать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute modal left-0 w-full h-full flex items-center justify-center top-0',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium',
    label: 'mt-[10px] text-grey-text',
    input: 'outline-none border-main border-[1px] px-[12px] rounded-[12px] py-[10px] mt-[5px] w-full text-black font-regular',
    divBtns: 'flex flex-row items-center justify-between mt-[20px]',
    btnClose: 'bg-grey px-[20px] py-[10px] rounded-[12px] text-black font-medium',
    btnCreate: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium'
}
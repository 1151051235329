import * as React from 'react';
import { ModalAddSubCategory } from '../../components/modals/ModalAddSubCategory';
import { RenderSubCategory } from '../../components/RenderSubCategory';
import { Request } from '../../requests/request';
import { useSelector, useDispatch } from 'react-redux';
import { ModalEditSubCategory } from '../../components/modals/ModalEditSubCategory';
import { Loader } from '../../components/Loader';
import { setSubCategoriesRedux } from '../../redux/categories/subcategories';
import { setCategoriesRedux } from '../../redux/categories/categories';
import { CustomSelect2 } from '../../components/CustomSelect';

export const SubCategory = () => {
    const categoriesRedux = useSelector(state => state.categories.categories);
    const token = useSelector(state => state.auth.session.token);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);
    const [filterCategory, setFilterCategory] = React.useState({label: 'Все', value: ''});

    const [modalAdd, setModalAdd] = React.useState(false);
    const [modalEdit, setModalEdit] = React.useState(false);
    const [modalEditIndex, setModalEditIndex] = React.useState(null);

    const [search, setSearch] = React.useState('');
    const dispatch = useDispatch();

    React.useEffect(() => {
        if(search !== '') {
            try {
                setLoading(true);
                let response = Request(`/api/subcategories/search/admin?search=${search}`, 'get', token, null);
                response.then((json) => {
                    if(json.status === 200) {
                        dispatch(setSubCategoriesRedux(json.data.data));
                        setData(json.data.data);
                    }
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        } else {
            try {
                setLoading(true);
                let response = Request(`/get/subcategories`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        dispatch(setSubCategoriesRedux(json.data.data));
                        setData(json.data.data);
                    };
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
            try {
                let response = Request(`/get/categories`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200) {
                        dispatch(setCategoriesRedux(json.data.data));
                    }
                })
            } catch(e) {
                console.log('e', e);
            }
        }
    }, [search]);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            try {
                let response = Request(`/get/categories`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200) {
                        dispatch(setCategoriesRedux(json.data.data));
                    }
                })
            } catch(e) {
                console.log('e', e);
            }
            try {
                setLoading(true);
                let response = Request(`/get/subcategories`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setData(json.data.data);
                        dispatch(setSubCategoriesRedux(json.data.data));
                    };
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className='flex flex-row items-start justify-between mt-[10px]'>
                <p className={styles.title}><label className={styles.label}>Подкатегории</label>: Найдено {data.length}</p>
            </div>
            <div className='mt-[10px] flex items-center justify-between'>
                <input 
                    value={search}
                    onChange={(event) => { setSearch(event.target.value); }}
                    className={styles.search}
                    placeholder="Поиск"
                />
                <CustomSelect2 
                    data={[{label: 'Все', value: ''}].concat(categoriesRedux.map((item, index) => {return{ label: item.name, value: item._id }}))}
                    value={filterCategory}
                    onChange={(value) => { setFilterCategory(value); }}
                    className1="w-1/3"
                />
            </div>
            <div className='flex mt-[10px] items-center justify-end'>
                <button
                    className={styles.btnAdd}
                    onClick={() => {
                        setModalAdd(true);
                    }}
                >Добавить</button>
            </div>
            <div>
            {
                loading? 
                <Loader />:
                data.filter((item, index) => (item.parent === filterCategory.value && filterCategory.value !== '') || (filterCategory.value === '' && true)).map((item, index) => 
                    <RenderSubCategory 
                        item={item}
                        index={index}
                        key={index.toString()}
                        token={token}
                        categories={categoriesRedux}
                        onSuccess={() => {
                            try {
                                setLoading(true);
                                let response = Request(`/get/subcategories`, 'get', null, null);
                                response.then((json) => {
                                    if(json.status === 200 && json.data.status === 'success') {
                                        setData(json.data.data);
                                        dispatch(setSubCategoriesRedux(json.data.data));
                                    };
                                    setLoading(false);
                                });
                            } catch(e) {
                                console.log('e', e);
                                setLoading(false);
                            };
                        }}
                        onEdit={() => {
                            setModalEditIndex(index);
                            setModalEdit(true);
                        }}
                    />
                )
            }
            </div>
            {
                modalAdd && 
                <ModalAddSubCategory 
                    close={() => { setModalAdd(false); }}
                    onSuccess={() => {
                        setModalAdd(false);
                        try {
                            setLoading(true);
                            let response = Request(`/get/subcategories`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    setData(json.data.data);
                                    dispatch(setSubCategoriesRedux(json.data.data));
                                };
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                    token={token}
                />
            }
            {
                modalEdit && 
                <ModalEditSubCategory 
                    item={data[modalEditIndex]}
                    token={token}
                    close={() => {setModalEdit(false); }}
                    onSuccess={async () => {
                        setModalEdit(false);
                        try {
                            setLoading(true);
                            let response = await Request(`/get/subcategories`, 'get', null, null);
                            if(response.status === 200) {
                                setData(response.data.data);
                                dispatch(setSubCategoriesRedux(response.data.data));
                            }
                            setLoading(false);
                            setModalEdit(false);
                            // response.then((json) => {
                            //     console.log('json', json);
                            //     if(json.status === 200 && json.data.status === 'success') {
                            //         setData(json.data.data);
                            //         dispatch(setSubCategoriesRedux(json.data.data));
                            //     };
                            //     setLoading(false);
                            //     setModalEdit(false); 
                            // }).catch((e) => {
                            //     console.log('e', e);
                            //     setLoading(false);
                            // })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: 'container px-[20px] overflow-y-scroll w-full h-full',
    btnAdd: 'px-[20px] bg-main py-[10px] rounded-[12px] text-white font-medium text-[16px] w-[150px] flex items-center justify-center',
    title: 'px-[20px] font-medium text-[20px]',
    label: 'text-main',
    search: 'border-main border-[1px] px-[20px] w-1/4 h-[45px] rounded-[12px] text-[16px] font-regular outline-main'
}
import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    id: 0,
    name: "",
    surname: '',
    nickname: '',
    role: '',
    email: '',
    phone: ''
};

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state = Object.assign(state, action.payload);
        },
        defaultUser: (state, action) => {
            state = initialState;
        }
    }
});

export const {setUser, defaultUser} = userSlice.actions;

export default userSlice.reducer;
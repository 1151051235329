import * as React from 'react';
import { Request } from '../../requests/request';

export const ModalLog = (props) => {
    const {
        setModalVisible,
        value, onSuccessDelete
    } = props;

    return(
        <div className='w-full left-0 h-screen absolute top-0 flex items-center justify-center'>
            <div 
                className='bg-white shadow-[0_0px_4px_0.3px_rgba(0,0,0,0.3)] p-[20px] rounded-[10px] w-2/3'
            >
                <p className='text-[20px] text-main font-medium'>{value.email}</p>
                <p className='mt-[10px] text-[18px] text-grey-text'>Дата создания: <label className='font-medium text-black'>{new Date().toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</label></p>
                <p className='text-[18px] text-grey-text'>Коллекции: <label className='text-main font-medium'>{value.collections}</label></p>
                <p className='mt-[10px] text-[18px] text-grey-text'>Маршрут: <label className='text-red font-medium'>{value.method} {value.route}</label></p>
                <div className='overflow-y-scroll'>
                    <p className='w-[80%]'><label className='text-grey-text text-[18px]'>Тело:</label> {value.data}</p>
                </div>
                <div className='flex flex-row items-center justify-between mt-[20px]'>
                    <button
                        className='bg-grey h-[41px] w-[150px] rounded-[10px] font-medium'
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >Закрыть</button>
                    <button
                        className='bg-red h-[41px] w-[150px] rounded-[10px] text-white font-medium'
                        onClick={() => {
                            let response = Request(`/api/logs`, 'delete', null, JSON.stringify({
                                email: 'miise95mmaks@mail.ru',
                                id: value._id
                            }));

                            response.then((json) => {
                                if(json.status === 200) {
                                    onSuccessDelete();
                                    setModalVisible(false);
                                    console.log('json', json);
                                } else {
                                    alert('Что-то пошло не так')
                                };
                            }).catch((e) => {
                                alert(e.message);
                            });
                        }}
                    >Удалить</button>
                </div>
                
            </div>
            
        </div>
    );
}
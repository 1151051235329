import * as React from 'react';
import { Request } from '../../requests/request';
import { CheckBox } from '../CheckBox';
import { CustomSelect, CustomSelect2 } from '../CustomSelect';

export const ModalAddSubCategory = (props) => {
    const { close, onSuccess, token } = props;
    
    const [name, setName] = React.useState('');
    const [visible, setVisible] = React.useState(false);
    const [parent, setParent] = React.useState({label: '', value: ''});
    const [parentId, setParentId] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [categories, setCategories] = React.useState([]);
    const refRepeat = React.useRef(false);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            try {
                setLoading(true);
                let response = Request(`/get/categories`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setCategories(json.data.data);
                    }
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Новая подкатегория</p>
                <p className={styles.label}>Название</p>
                <input 
                    className={styles.input}
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />
                <CheckBox 
                    value={visible}
                    onChange={(value) => { setVisible(value); }}
                    text="Статус активности"
                />
                <p className={styles.label}>Категория</p>
                <CustomSelect2
                    className1="w-2/4"
                    data={categories.map((item, index) => {return {label: item.name, value: item.name, _id: item._id}})}
                    value={parent}
                    onChange={(value) => { setParent(value.value); setParentId(value._id); }}
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            close();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnCreate}
                        onClick={() => {
                            try {
                                let response = Request(`/post/subcategory`, 'post', token, JSON.stringify({
                                    data: {
                                        name: name,
                                        parent: parentId,
                                        visible: visible
                                    }
                                }));
                                response.then((json) => {
                                    if(json.status === 200 && json.data.status === 'success') {
                                        onSuccess();
                                    };
                                })
                            } catch(e) {
                                console.log('e', e);
                            };
                        }}
                    >Создать</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute top-0 modal w-full h-full flex items-center justify-center',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium',
    label: 'text-[16px] text-grey-text mt-[10px]',
    input: 'outline-none border-main border-[1px] rounded-[12px] w-full px-[12px] py-[10px] mt-[10px] text-[16px] font-regular',
    divBtns: 'mt-[20px] flex flex-row items-center justify-between w-full',
    btnClose: 'px-[20px] py-[10px] rounded-[12px] bg-grey text-black font-medium',
    btnCreate: 'px-[20px] py-[10px] rounded-[12px] bg-main text-white font-medium'
}
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoaderAbsolute } from '../../components/Loader';
import { setUser } from '../../redux/auth/userSlice';
import { setCityUserSlice, setGeolocationUserSlice } from '../../redux/users/users';
import { Request } from '../../requests/request';
import { ModalCity } from '../../components/modals/ModalCity';

export const Profile = () => {
    const token = useSelector(state => state.auth.session.token);
    const dispatch = useDispatch();

    const nameRedux = useSelector(state => state.auth.user.name);
    const surnameRedux = useSelector(state => state.auth.user.name);
    const midnameRedux = useSelector(state => state.auth.user.name);

    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState({
        name: nameRedux,
        surname: surnameRedux,
        midname: midnameRedux,
        nickname: '',
        role: '',
        phone: '',
        email: '',
        created: new Date()
    });
    const refRepeat = React.useRef(false);

    const geolocationRedux = useSelector(state => state.users.geolocation);
    const cityRedux = useSelector(state => state.users.city);

    console.log('users', cityRedux, geolocationRedux);

    const [geolocation, setGeolocation] = React.useState(geolocationRedux);

    const [modalVisible, setModalVisible] = React.useState(false);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            try {
                setLoading(true);
                let tempData = Request(`/get/admins/me`, 'get', token, null); 
                
                tempData.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data)
                        dispatch(setUser(json.data.data));
                    }
                    setLoading(false);
                });
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <p className={styles.title}>Профиль</p>
            <p className={styles.label}>Имя</p>
            <input 
                className={styles.input}
                value={data.name}
                onChange={(event) => {
                    setData({
                        ...data,
                        name: event.target.value
                    })
                }}
            />
            <p className={styles.label}>Фамилия</p>
            <input 
                className={styles.input}
                value={data.surname}
                onChange={(event) => {
                    setData({
                        ...data,
                        surname: event.target.value
                    });
                }}
            />
            <p className={styles.label}>Отчество</p>
            <input 
                className={styles.input}
                value={data.midname}
                onChange={(event) => {
                    setData({
                        ...data,
                        midname: event.target.value
                    })
                }}
            />
            <div className='mt-[10px]'>
                <button
                    className={styles.btnLocation}
                    onClick={() => {
                        setModalVisible(true);
                    }}
                >{cityRedux}</button>
            </div>
            
            <div className={styles.divBtns}>
                <button 
                    className={styles.btnSave}
                    onClick={() => {
                        const response = Request(`/put/admin`, 'put', token, JSON.stringify({
                            data: data,
                            id: data._id
                        }));

                        response.then((json) => {
                            if(json.status === 200) {
                                dispatch(setUser({
                                    name: data.name,
                                    surname: data.surname,
                                    midname: data.midname,
                                    city: cityRedux,
                                    // role: 'admin'
                                }));
                            }
                        })
                    }}
                >Сохранить</button>
            </div>
            {
                loading && <LoaderAbsolute />
            }
            {
                modalVisible &&
                    <ModalCity 
                        setModalVisible={setModalVisible}
                    /> 
            }
        </div>
    );
};

const styles = {
    main: 'container mx-auto',
    title: 'text-[20px] font-medium text-black mt-[10px]',
    input: 'border-main border-[1px] rounded-[12px] mt-[5px] py-[10px] text-[16px] font-regular outline-none px-[12px]',
    label: 'mt-[10px] text-[16px] text-grey-text',
    divBtns: 'mt-[20px]',
    btnSave: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium text-[18px]',
    btnLocation: 'w-1/5 bg-main h-[41px] rounded-[10px] text-white font-medium'
}
import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    subcategories: []
};

export const subcategoriesSlice = createSlice({
    name: 'subcategories',
    initialState,
    reducers: {
        setSubCategoriesRedux: (state, action) => {
            state.subcategories = action.payload;
        }
    }
});

export const {setSubCategoriesRedux} = subcategoriesSlice.actions;

export default subcategoriesSlice.reducer;
import * as React from 'react';
import { CheckBox } from '../CheckBox';
import { CustomSelect, CustomSelect2 } from '../CustomSelect';
import { Request } from '../../requests/request';

export const ModalAddCities = (props) => {
    const {token, onSuccess, close} = props;

    const [name, setName] = React.useState('');
    const [visible, setVisible] = React.useState(false);
    const [regions, setRegions] = React.useState([]);
    const [region, setRegion] = React.useState('');
    const [regionId, setRegionId] = React.useState(null);

    const refRepeat = React.useRef(false);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            try {
                let response = Request(`/get/regions`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setRegions(json.data);
                    }
                })
            } catch(e) {
                console.log('e', e);
            }
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Новый город</p>
                <p className={styles.label}>Название</p>
                <input 
                    className={styles.input}
                    value={name}
                    onChange={(event) => { setName(event.target.value); }}
                />
                <p className={styles.label}>Регион</p>
                <div className='mt-[10px]'>
                    <CustomSelect2
                        data={regions.map((item, index) => {return {value: item.name, label: item.name}})}
                        value={region}
                        onChange={(value) => {
                            setRegion(value.value);
                            setRegionId(value._id);
                        }}
                    />
                </div>
                
                <CheckBox 
                    value={visible}
                    onChange={(value) => {setVisible(value); }}
                    text="Активность"
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            close();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnCreate}
                        onClick={() => {
                            try {
                                let response = Request(`/post/city`, 'post', token, JSON.stringify({
                                    data: {
                                        name: name,
                                        visible: visible,
                                        region: regionId
                                    }
                                }));

                                response.then((json) => {
                                    if(json.status === 200 && json.data.status === 'success') {
                                        onSuccess();
                                    }
                                })
                            } catch(e) {
                                console.log('e', e);
                            };
                        }}
                    >Добавить</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: "absolute modal top-0 left-0 flex items-center justify-center w-full h-full",
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[16px] font-medium',
    input: 'text-[16px] font-regular border-main border-[1px] rounded-[12px] px-[12px] py-[10px] mt-[10px] w-full outline-none',
    label: 'text-[16px] font-regular text-grey-text mt-[10px]',
    divBtns: 'mt-[20px] flex flex-row items-center justify-between',
    btnClose: 'bg-grey px-[20px] py-[10px] rounded-[12px] text-black font-medium',
    btnCreate: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium'
}
import * as React from 'react';

export const RenderRegions = (props) => {
    const {
        item, index, token,
        onDeleteSuccess, onEdit
    } = props;

    return(
        <div className={styles.main(index)}>
            <div 
                className={styles.div}
                onClick={() => { onEdit(); }}    
            >
               <p className={styles.name}>{item.name}</p>
               <p className={styles.visible(item.visible)}>{item.visible? 'Активно': 'Не активно'}</p>
            </div>
            <button
                className={styles.btnDelete}
            >Удалить</button>
        </div>
    );
};

const styles = {
    main: (index) => `flex flex-row items-center justify-between bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] mb-[0px] ${index === 0? 'mt-[20px]': ''}`,
    div: 'w-full',
    name: 'text-[16px] font-medium text-black',
    visible: (visible) => `text-[16px] font-regular ${visible? 'text-main ': 'text-red '}`,
    btnDelete: 'text-red text-[16px] font-medium py-[10px] px-[20px]'
}
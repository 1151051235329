import * as React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Loader';
import { ModalAddNationality } from '../../components/modals/ModalAddNationality';
import { ModalEditNationality } from '../../components/modals/ModalEditNationality';
import { RenderNationality } from '../../components/RenderNationality';
import { Request } from '../../requests/request';

export const Nationality = () => {
    const token = useSelector(state => state.auth.session.token);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    const [modalAddVisible, setModalAddVisible] = React.useState(false);
    const [modalEditVisible, setModalEditVisible] = React.useState(false);
    const [modalEditIndex, setModalEditIndex] = React.useState(-1);

    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        if(search.length === 0) {
            //if(refRepeat.current === false) {
                try {
                    setLoading(true);
                    const response = Request('/api/nationalities', 'get', null, null);
                    response.then((json) => {
                        if(json.status === 200) {
                            setData(json.data.data);
                        };
                        setLoading(false);
                    })
                } catch(e) {
                    console.log('e', e);
                    setLoading(false);
                }
            //}
        } else {
            try {
                setLoading(true);
                const response = Request(`/api/nationalities/search?search=${search}`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    }
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            }
        }
        
        return () => refRepeat.current = true
    }, [search]);

    return(
        <div className={styles.main}>
            <div className={styles.divHeader}>
                <p className={styles.title}>Гражданство - Найдено {data.length}</p>
            </div>
            <div className='mt-[10px] flex items-center justify-between'>
                <input 
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value);
                        refRepeat.current = false;
                    }}
                    placeholder='Поиск'
                    className="border-main border-[1px] py-[10px] px-[20px] w-1/3 rounded-[12px] outline-main"
                />
                <button
                    className={styles.btnAdd}
                    onClick={() => {
                        setModalAddVisible(true);
                    }}
                >Добавить</button>
            </div>
            <div className='grid grid-cols-1 laptop:grid-cols-2'>
            {
                loading?
                    <Loader />:
                    data.map((item, index) => 
                        <RenderNationality 
                            item={item}
                            index={index}
                            key={index.toString()}
                            token={token}
                            onSuccess={() => {
                                try {
                                    setLoading(true);
                                    const response = Request('/api/nationalities', 'get', null, null);
                                    response.then((json) => {
                                        if(json.status === 200) {
                                            setData(json.data.data);
                                        };
                                        setLoading(false);
                                    })
                                } catch(e) {
                                    console.log('e', e);
                                    setLoading(false);
                                };
                            }}
                            onClick={(index) => {
                                setModalEditIndex(index);
                                setModalEditVisible(true);
                            }}
                        />
                    )
            }
            </div>
            {
                modalAddVisible && 
                <ModalAddNationality 
                    onClose={() => { setModalAddVisible(false); }}
                    token={token}
                    onSuccess={() => {
                        setModalAddVisible(false);
                        try {
                            setLoading(true);
                            const response = Request('/api/nationalities', 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200) {
                                    setData(json.data.data);
                                };
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
            {
                modalEditVisible && 
                <ModalEditNationality 
                    token={token}
                    item={data[modalEditIndex]}
                    onClose={() => { setModalEditVisible(false); }}
                    onSuccess={() => {
                        setModalEditVisible(false);
                        try {
                            setLoading(true);
                            const response = Request('/api/nationalities', 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200) {
                                    setData(json.data.data);
                                };
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        }
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: 'container px-[20px] h-full overflow-y-scroll',
    divHeader: 'flex flex-row items-center justify-between mt-[10px]',
    title: 'text-[20px] font-medium',
    btnAdd: 'bg-main px-[20px] py-[10px] rounded-[12px] text-white font-medium text-[16px] w-[150px]'
}
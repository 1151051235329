import * as React from 'react';

export const MultiInput = (props) => {
    const {value, onChange, placeholder} = props;

    return(
        <textarea 
            className={styles.main}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
};

const styles = {
    main: 'w-1/3 min-h-[112px] rounded-[12px] border-[#BFE9CB] border-[1px] mb-[16px] outline-none p-[12px] text-[16px] font-regular'
};
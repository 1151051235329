import * as React from 'react';

export const ViewChat = () => {
    return(
        <div className={styles.main}>
            <p>ViewChat</p>
        </div>
    );
};

const styles = {
    main: ''
}
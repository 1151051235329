import * as React from 'react';

export const ViewCategories = (props) => {
    const {data} = props;

    return(
        <div className={styles.main}>
            {
                data.length > 0?
                    data.map((item, index) => 
                        <div className={styles.div} key={index.toString()}>
                            <label className={styles.text}>{item.name}</label>
                        </div>
                    ):
                <p className='text-[16px] font-regular text-[#2E2E2E]'>Категории не добавлены</p>
            }
        </div>
    );
};

const styles = {
    main: 'flex flex-row overflow-x-scroll mb-[16px]',
    div: 'mr-[8px] bg-[#F3F4F8] p-[8px] rounded-[12px]',
    text: 'text-[16px] font-regular text-[2E2E2E]'
};
export const pageCount = 10;

export const initialUser = {
    performers: {
        physical: {
            avatar: "",
            name: '',
            surname: '',
            midname: '',
            inn: '',
            innVerified: false,
            birthdate: new Date(),
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            experience: '',
            vaccination: '',
            sanitary: '',
            driverLicense: '',
            character: '',
            sphere: '',
            profession: '',
            priceType: '',
            minPrice: 0,
            maxPrice: 0,
            birthdate: new Date(),
            nationality: '',
            gender: '',
            aboutMe: '',
            visible: false
        },
        selfEmployed: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            inn: '',
            innVerified: false,
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            experience: '',
            vaccination: '',
            sanitary: '',
            driverLicense: '',
            character: '',
            sphere: '',
            profession: '',
            priceType: '',
            minPrice: 0,
            maxPrice: 0,
            birthdate: new Date(),
            nationality: '',
            gender: '',
            aboutMe: '',
            visible: false
        },
        ip: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            nameOrg: '',
            inn: '',
            innVerified: false,
            sphere: '',
            profession: '',
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            visible: false,
            birthdate: new Date(),
            minPrice: 0,
            maxPrice: 0,
            priceType: '',
            experience: '',
            vaccination: '',
            sanitary: '',
            driverLicense: '',
            character: ''
        },
        organization: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            nameOrg: '',
            inn: '',
            innVerified: false,
            sphere: '',
            profession: '',
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            visible: false,
            birthdate: new Date(),
            minPrice: 0,
            maxPrice: 0,
            priceType: '',
            experience: '',
            vaccination: '',
            sanitary: '',
            driverLicense: '',
            character: ''
        }
    },
    clients: {
        physical: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            inn: '',
            innVerified: false,
            sphere: '',
            profession: '',
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            birthdate: new Date(),
            nationality: '',
            gender: '',
            aboutMe: '',
            visible: false,
            minPrice: 0,
            maxPrice: 0,
            priceType: ''
        },
        selfEmployed: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            inn: '',
            innVerified: false,
            sphere: '',
            profession: '',
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            birthdate: new Date(),
            nationality: '',
            gender: '',
            aboutMe: '',
            visible: false,
            minPrice: 0,
            maxPrice: 0,
            priceType: ''
        },
        ip: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            nameOrg: '',
            inn: '',
            innVerified: false,
            sphere: '',
            profession: '',
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            visible: false,
            birthdate: new Date(),
            minPrice: 0,
            maxPrice: 0,
            priceType: ''
        },
        organization: {
            avatar: '',
            name: '',
            surname: '',
            midname: '',
            nameOrg: '',
            inn: '',
            innVerified: false,
            sphere: '',
            profession: '',
            address: '',
            geolocation: { latitude: 0, longitude: 0 },
            visible: false,
            birthdate: new Date(),
            minPrice: 0,
            maxPrice: 0,
            priceType: ''
        }
    }
}
import * as React from 'react';
import { CheckBox } from '../CheckBox';
import {Request} from '../../requests/request';
import { CustomSelect, CustomSelect2 } from '../CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setCategoriesRedux } from '../../redux/categories/categories';

export const ModalEditSubCategory = (props) => {
    const {item, token, close, onSuccess} = props;
    const categoriesRedux = useSelector(state => state.categories.categories);
    const [categories, setCategories] = React.useState(categoriesRedux);

    const [name, setName] = React.useState(item.name);
    const [visible, setVisible] = React.useState(item.visible);
    const [parent, setParent] = React.useState({value: categories.find(({ _id}) => _id === item.parent)?._id, label: categories.find(({ _id}) => _id === item.parent)?.name});
    const [parentId, setParentId] = React.useState(item.parent);
    
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);
    const dispatch = useDispatch();
    const [errorState, setErrorState] = React.useState(false);
    

    React.useEffect(() => {
        if(refRepeat.current === false) {
            try {
                setLoading(true);
                let response = Request(`/get/categories`, 'get', null, null);
                response.then((json) => {
                    if(json.status === 200 && json.data.status === 'success') {
                        setCategories(json.data.data);
                        dispatch(setCategoriesRedux(json.data.data));
                    }
                    setLoading(false);
                })
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        }
        return () => refRepeat.current = true;
    }, []);

    return(
        <div className={styles.main}>
            <div className={styles.div}>
                <p className={styles.title}>Изменить подкатегорию</p>
                <p className={styles.label}>Название<label className='text-red'>*</label></p>
                <input
                    className={styles.input(errorState)}
                    value={name}
                    onChange={(event) => { setName(event.target.value); }}
                />
                <CheckBox 
                    value={visible}
                    onChange={(value) => { setVisible(value); }}
                    text="Показать"
                />
                <p className={styles.label}>Категория<label className='text-red'>*</label></p>
                <CustomSelect2 
                    data={categories.map((item, index) => {return {value: item._id, label: item.name}})}
                    value={parent}
                    onChange={(value) => {
                        setParentId(value.value);
                        setParent(value.label);
                    }}
                    className1={`w-1/2 mt-[10px]`}
                />
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            close();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnSave}
                        onClick={async () => {
                            if(name !== '' && parentId !== null) {
                                setErrorState(false);
                                try {
                                    let response = await Request(`/put/subcategory`, 'put', token, JSON.stringify({
                                        data: {
                                            id: item._id,
                                            name: name,
                                            parent: parentId,
                                            visible: visible
                                        }
                                    }));
                                    console.log('responses', response);
                                    if(response.status === 200) {
                                        onSuccess();
                                    };
                                } catch(e) {
                                    console.log('e', e);
                                };
                            } else {
                                setErrorState(true);
                            }
                            
                        }}
                    >Изменить</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    main: "absolute modal left-0 top-0 flex w-full h-full items-center justify-center",
    div: 'bg-white p-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium',
    label: 'text-[16px] text-grey-text mt-[10px]',
    input: (errorState) => `mt-[10px] px-[12px] ${errorState? 'border-red ': 'border-main '} w-3/5 border-[1px] rounded-[12px] py-[10px] text-[16px] font-regular`,
    divBtns: 'flex flex-rows items-center justify-between mt-[20px]',
    btnClose: 'px-[20px] bg-grey py-[10px] rounded-[12px] text-black font-medium',
    btnSave: 'px-[20px] bg-main py-[10px] rounded-[12px] text-white font-medium'
}
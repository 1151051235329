import * as React from 'react';
import {useSelector} from 'react-redux';
import { Loader } from '../../components/Loader';
import { ModalAddAdmin } from '../../components/modals/ModalAddAdmin';
import { ModalEditAdmin } from '../../components/modals/ModalEditAdmin';
import { RenderAdmin } from '../../components/RenderAdmin';
import { Request } from '../../requests/request';

export const AdminsMod = () => {
    const token = useSelector(state => state.auth.session.token);

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    const [modalEdit, setModalEdit] = React.useState(false);
    const [modalEditIndex, setModalEditIndex] = React.useState(null);
    const [modalAdd, setModalAdd] = React.useState(false);

    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        if(search.length === 0) {
            // if(refRepeat.current === false) {
                setLoading(true);
                try {
                    let response = Request(`/get/admins`, 'get', token, null);
                    response.then((json) => {
                        if(json.status === 200 && json.data.status === 'success') {
                            setData(json.data.data);
                        }
                        setLoading(false);
                    })
                } catch(e) {
                    console.log('e', e);
                    setLoading(false);
                };
            // }
        } else {
            setLoading(true);
            try {
                let response = Request(`/get/search/admin?search=${search}`, 'get', token, null);
                response.then((json) => {
                    if(json.status === 200) {
                        setData(json.data.data);
                    }
                    setLoading(false);
                });
            } catch(e) {
                console.log('e', e);
                setLoading(false);
            };
        };
        
        return () => refRepeat.current = true;
    }, [search]); 

    return(
        <div className={styles.main}>
            <div className='flex flex-row items-start justify-between mt-[10px]'>
                <p className={styles.title}><label className={styles.label}>Админы и модераторы</label>: Найдено {data.length}</p>
                
            </div>
            <div className='mt-[10px] flex items-center justify-between'>
                <input 
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    placeholder="Поиск"
                    className='border-main border-[1px] rounded-[12px] py-[10px] px-[20px] outline-main w-1/3 text-[16px] text-black font-regular'
                />
                <button 
                    className={styles.btnAdd}
                    onClick={() => {
                        setModalAdd(true);
                    }}
                >Новый</button>
            </div>
            <div className='grid grid-cols-1 laptop:grid-cols-2'>
            {
                loading?
                    <Loader />:
                    data.map((item, index) => 
                        <RenderAdmin 
                            item={item}
                            index={index}
                            key={index.toString()}
                            onView={() => {
                                setModalEditIndex(index);
                                setModalEdit(true);
                            }}
                        />
                    )
            }
            </div>
            {
                modalAdd && 
                <ModalAddAdmin 
                    close={() => { setModalAdd(false); }}
                    token={token}
                    onSuccess={() => {
                        setModalAdd(false);
                        try {
                            let response = Request(`/get/admins`, 'get', token, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    setData(json.data.data);
                                }
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }}
                />
            }
            {
                modalEdit && 
                <ModalEditAdmin 
                    close={() => { setModalEdit(false); }} 
                    onSuccess={() => { 
                        setModalEdit(false);
                        try {
                            let response = Request(`/get/admins`, 'get', token, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    setData(json.data.data);
                                }
                                setLoading(false);
                            })
                        } catch(e) {
                            console.log('e', e);
                            setLoading(false);
                        };
                    }} 
                    token={token} 
                    item={data[modalEditIndex]}
                />
            }
        </div>
    );
};

const styles = {
    main: 'container px-[20px] h-full overflow-y-scroll relative',
    label: 'text-main',
    title: 'text-[20px] font-medium',
    btnAdd: "px-[20px] bg-main text-white py-[10px] font-medium rounded-[12px] w-[150px]"
}
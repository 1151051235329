import * as React from 'react';
import { getRole } from '../configs/functions';

export const RenderAdmin = (props) => {
    const {
        item, index, onView
    } = props;

    return(
        <div
            key={index.toString()} 
            className={styles.main(index)}
            onClick={() => { onView(); }}
        >
            <div>
                <p><label className={styles.label}>ФИО:</label> <label className={styles.name}>{item.name} {item.surname}</label></p>
                <p><label className={styles.label}>Email:</label> <label className={styles.email}>{item.email}</label></p>
                <p><label className={styles.label}>Роль:</label> <label className={styles.role}>{getRole(item.role)}</label></p>
            </div>
        </div>
    );
};

const styles = {
    main: (index) => `p-[20px] mb-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] rounded-[12px] ${index === 0? 'mt-[20px] ': ' '}`,
    name: 'text-[16px] font-medium text-black',
    email: 'text-[16px] font-medium text-black',
    role: 'text-[16px] font-medium text-grey-text',
    label: 'text-[16px] font-regular'
}
import * as React from 'react';
import { getCurrentTypeUser, typePrice } from '../../configs/functions';
import { Request } from '../../requests/request';
import { CheckBox } from '../CheckBox';
import { CustomSelect2 } from '../CustomSelect';
import { ModalAddress } from './ModalAddress';
import { ModalAddUser } from './ModalAddUser';
import { useDispatch } from 'react-redux';
import { setUsersRedux} from '../../redux/users/users';
import { ModalAddCategory } from './ModalAddCategory';
import { ModalAddSubCategory } from './ModalAddSubCategory';
import { setCategoriesRedux } from '../../redux/categories/categories';
import { setSubCategoriesRedux } from '../../redux/categories/subcategories';

export const ModalAddVacation = (props) => {
    const {token, onClose, onSuccess, users, categories, subcategories} = props;

    const [data, setData] = React.useState({
        character: {value: 'Разовая', label: 'Разовая'},
        typeWork: {value: 'На месте', label: 'На месте'},
        category: {value: '', label: '', _id: ''},
        subcategory: {value: '', label: ''},
        description: '',
        price: {
            minPrice: 0,
            maxPrice: 0
        },
        typePrice: {value: '₽ / в месяц', label: '₽ / в месяц'},
        typeUser: {value: 'Физическое лицо', label: 'Физическое лицо'},
        address: '',
        experience: false,
        vaccination: false,
        sanitary: false,
        driverLicense: false,
        onlyOrganization: false,
        onlyPhysical: false,
        visible: false,
        userType: '',
        name: '',
        surname: '',
        avatar: '',
        idUser: '',
        fcmToken: '',
        phone: '',
        city: '',
        region: '',
        address: '',
        photo: '',
        avatar: '',
        placement: {
            latitude: 0,
            longitude: 0,
            address: ''
        },
        city: '',
        region: ''
    });
    const [chooseUser, setChooseUser] = React.useState({
        label: '',
        value: -1
    });
    const [modalAddress, setModalAddress] = React.useState(false);
    const [modalCategory, setModalCategory] = React.useState(false);
    const [modalSubCategory, setModalSubCategory] = React.useState(false);
    const [modalUser, setModalUser] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if(chooseUser.value !== -1) {
            setData({
                ...data,
                name: users[chooseUser.value].name,
                surname: users[chooseUser.value].surname,
                fcmToken: users[chooseUser.value].fcmToken,
                nameOrg: users[chooseUser.value].typeUser === 'Performer'? 
                    users[chooseUser.value].subTypeUser_performer === 'Организация'?
                        users[chooseUser.value].short_name_org_performer:
                        '':
                    users[chooseUser.value].subTypeUser_client === 'Организация'?
                        users[chooseUser.value].short_name_org_client:
                        '',
                userType: users[chooseUser.value].userType === 'Performer'? users[chooseUser.value].subTypeUser_performer: users[chooseUser.value].subTypeUser_client,
                avatar: users[chooseUser.value].typeUser === 'Performer'? users[chooseUser.value].avatar_performer: users[chooseUser.value].avatar_client
            });
        }
    }, [chooseUser.value]);

    React.useEffect(() => {
        if(data.category.value === 'Новый') {
            setModalCategory(true);
            setData({
                ...data,
                category: {value: '', label: ''}
            });
        } 
        if(data.subcategory.value === 'Новый') {
            setModalSubCategory(true);
            setData({
                ...data,
                subcategory: {value: '', label: ''}
            })
        }
    }, [data.category.value, data.subcategory.value]);

    return(
        <div className={styles.main}>
            <div className={styles.div + ' h-[90%] overflow-y-scroll'}>
                <p className={styles.title}>Новая вакансия</p>
                <div className='flex flex-row items-center justify-start'>
                    <div>
                        <p className={styles.label}>Категория<label className='text-red'>*</label>:</p>
                        <div className='mt-[10px]'>
                            <CustomSelect2
                                data={[{value: 'Новый', label: 'Новый'}].concat(categories.map((item, index) => {return{value: item.name, label: item.name, _id: item._id}}))}
                                value={data.category}
                                onChange={(value) => { setData({...data, category: value}) }}
                                className1='w-[200px] mr-[20px]'
                            />
                        </div>
                    </div>
                    <div>
                        <p className={styles.label}>Подкатегория<label className='text-red'>*</label>:</p>
                        <div className='mt-[10px]'>
                            <CustomSelect2
                                data={[{value: 'Новый', label: 'Новый'}].concat(subcategories.filter((item, index) => item.parent === data.category._id).map((item, index) => {return{value: item.name, label: item.name}}))}
                                value={data.subcategory}
                                onChange={(value) => { setData({...data, subcategory: value}) }}
                                className1="w-[200px]"
                            />
                        </div>
                    </div>
                </div>
                <p className={styles.label}>Вознаграждение:<label className='text-red'>*</label></p>
                <div className={styles.divPrice}>
                    <div className='flex flex-row items-center'>
                        <input 
                            className={styles.price}
                            value={data.price.minPrice}
                            type={'number'}
                            onChange={(event) => {
                                setData({...data, price: {minPrice: event.target.value, maxPrice: data.price.maxPrice}});
                            }}
                        />
                        <p className='mx-[10px]'> - </p>
                        <input 
                            className={styles.price + ' mr-[20px]'}
                            value={data.price.maxPrice}
                            type={'number'}
                            onChange={(event) => {
                                setData({...data, price: {minPrice: data.price.minPrice, maxPrice: event.target.value} });
                            }}
                        />
                    </div>
                    
                    <CustomSelect2
                        data={typePrice.map((item, index) => {return{value: item.name, label: item.name}})}
                        value={data.typePrice}
                        onChange={(value) => { setData({...data, typePrice: value}); }}
                        className1="w-[200px]"
                    />
                </div>
                <p className={styles.label}>Пользователь:<label className='text-red'>*</label></p>
                <div className='flex flex-row'>
                    <CustomSelect2
                        data={users.map((item, index) => {
                            return{
                                value: index, 
                                label: item.typeUser === 'Performer'?  
                                    item.subTypeUser_performer === 'Организация'?
                                        item.surname + ' ' + item.name + ' ' + item.midname + '/' + item.short_name_org_performer:
                                        item.surname + ' ' + item.name + ' ' + item.midname:
                                    item.subTypeUser_client === 'Организация'?
                                        item.surname + ' ' + item.name + ' ' + item.midname + '/' + item.short_name_org_client:
                                        item.surname + ' ' + item.name + ' ' + item.midname
                            } 
                        })}
                        value={chooseUser}
                        onChange={(value) => { setChooseUser(value); }}
                        className1={'mt-[10px] w-1/2 outline-none mr-[20px]'}
                    />
                    {
                        chooseUser.label !== ''?
                            <CustomSelect2
                                data={[{value: 'Физическое лицо', label: 'Физическое лицо'}, {value: "Самозанятый", label: 'Самозанятый'}, {value: "ИП", label: 'ИП'}, {value: 'Организация', label: 'Организация'}]}
                                value={data.typeUser}
                                onChange={(value) => { setData({...data, typeUser: value}); }}
                                className1='w-[200px] mt-[10px]'
                            />:
                            <></>
                    }
                </div>
                <button
                    className='mt-[10px] bg-main w-[250px] h-[41px] text-white rounded-[10px] font-medium text-[16px]'
                    onClick={() => { 
                        setModalUser(true);
                    }}
                >Новый пользователь</button>
                <p className={styles.label}>Адрес:<label className='text-red'>*</label></p>
                <button
                    className={styles.btnAddress}
                    onClick={() => { setModalAddress(true); }}
                >{data.address === ''? 'Выбрать': data.address}</button>
                <p className={styles.label}>Характер работы:</p>
                <div className='mt-[10px]'>
                    <CustomSelect2
                        data={[{value: 'Разовая', label: 'Разовая'}, {value: "Постоянная", label: 'Постоянная'}]}
                        value={data.character}
                        onChange={(value) => { setData({...data, character: value}); }}
                        className1='w-[200px]'
                    />
                </div>
                <p className={styles.label}>Тип работы:</p>
                <div className='mt-[10px]'>
                    <CustomSelect2
                        data={[{value: 'На месте', label: 'На месте'}, {value: 'Удаленно', label: 'Удаленно'}]}
                        value={data.typeWork}
                        onChange={(value) => {setData({...data, typeWork: value}); }}
                        className1="w-[200px]"
                    />
                </div>
                <p className={styles.label}>Описание:</p>
                <textarea
                    value={data.description}
                    onChange={(event) => { setData({...data, description: event.target.value}); }}
                    className={styles.textarea}
                />
                <CheckBox 
                    value={data.visible}
                    onChange={(value) => { setData({...data, visible: value}); }}
                    text="Статус активности"
                />
                <p className={styles.additional}>Дополнительные условия:</p>
                <div className={styles.divAdditional}>
                    <CheckBox 
                        value={data.experience}
                        onChange={(value) => {setData({...data, experience: value}); }}
                        text="Опыт работы требуется"
                    />
                    <CheckBox 
                        value={data.vaccination}
                        onChange={(value) => {setData({...data, vaccination: value}); }}
                        text="Вакцинация COVID-19 требуется"
                    />
                    <CheckBox 
                        value={data.sanitary}
                        onChange={(value) => {setData({...data, sanitary: value}); }}
                        text="Санкнижка требуется"
                    />
                    <CheckBox 
                        value={data.driverLicense}
                        onChange={(value) => {setData({...data, driverLicense: value}); }}
                        text="Водительские права требуется"
                    />
                    <CheckBox 
                        value={data.onlyOrganization}
                        onChange={(value) => {setData({...data, onlyOrganization: value}); }}
                        text="Только для самозанятых, ИП и организаций"
                    />
                    <CheckBox 
                        value={data.onlyPhysical}
                        onChange={(value) => {setData({...data, onlyPhysical: value}); }}
                        text="Только для физических лиц"
                    />
                </div>
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => {
                            onClose();
                        }}
                    >Закрыть</button>
                    <button
                        className={styles.btnSave}
                        onClick={() => {
                            if(chooseUser.value !== -1) {
                                try {
                                    const response = Request(`/api/vacations/admin`, 'post', token, JSON.stringify({
                                        data: {
                                            ...data,
                                            character: data.character.value,
                                            typeWork: data.typeWork.value,
                                            category: data.category.value,
                                            subcategory: data.subcategory.value,
                                            typePrice: data.typePrice.value,
                                            userType: data.typeUser.value
                                        }
                                    }));
        
                                    response.then((json) => {
                                        if(json.status === 200) {
                                            onSuccess();
                                        }
                                    }).catch((e) => {
                                        console.log('e api/vacations/admin', e);
                                    })
                                } catch(e) {
                                    console.log('e', e);
                                }
                            } else {
                                alert('Выберите пользователя');
                            }
                        }}
                    >Создать</button>
                </div>
            </div>
            {
                modalAddress &&
                <ModalAddress 
                    onChange={(value) => {
                        console.log('onChange', value);
                        let temp = data;
                        data.address = value.address;
                        data.placement.latitude = value.geolocation.latitude;
                        data.placement.longitude = value.geolocation.longitude;
                        data.placement.address = value.address;
                        setData(temp);
                        setModalAddress(false);
                    }}
                    close={() => {setModalAddress(false); }}
                    
                />
            }
            {
                modalUser && <ModalAddUser 
                    token={token}
                    setModalVisible={setModalUser}
                    onSuccess={() => {
                        const users = Request('/api/users', 'get', token, null);
                        users.then((json) => {
                            if(json.status === 200) {
                                dispatch(setUsersRedux(json.data.data));
                            };
                        })
                        setModalUser(false);
                    }}
                />
            }
            {
                modalCategory && <ModalAddCategory 
                    token={token}
                    close={() => { setModalCategory(false); }}
                    onSuccess={() => {
                        let tempData = Request(`/get/categories`, 'get', null, null);
                        tempData.then((json) => {
                            if(json.status === 200) {
                                dispatch(setCategoriesRedux(json.data.data));
                            }
                            setModalCategory(false);
                        });
                        
                    }}
                />
            }
            {
                modalSubCategory && <ModalAddSubCategory 
                    token={token}
                    close={() => { setModalSubCategory(false); }}
                    onSuccess={() => {
                        let response = Request(`/get/subcategories`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    dispatch(setSubCategoriesRedux(json.data.data));
                                };
                                setModalSubCategory(false);
                            })
                        
                    }}
                />
            }
        </div>
    );
};

const styles = {
    main: "absolute modal top-0 left-0 w-full h-full flex items-center justify-center",
    div: 'scroll bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-[50%]',
    title: 'text-[20px] font-medium text-black',
    label: 'mt-[20px] text-grey-text font-regular',
    input: 'mt-[10px] px-[20px] py-[10px] rounded-[12px] outline-none border-main border-[1px] w-3/4',
    divBtns: 'flex flex-row items-center justify-between mt-[20px]',
    btnClose: 'bg-grey px-[20px] py-[10px] rounded-[12px] font-medium text-[16px] text-black',
    btnSave: 'bg-main px-[20px] py-[10px] rounded-[12px] font-medium text-[16px] text-white',
    textarea: 'border-main border-[1px] rounded-[12px] mt-[10px] text-[16px] font-regular text-black outline-none px-[20px] py-[10px] h-min-[100px]',
    divPrice: 'flex flex-row items-center justify-start mt-[10px]',
    price: 'border-main border-[1px] rounded-[12px] px-[20px] py-[10px] outline-none w-[150px]',
    additional: 'mt-[20px] text-black text-[16px] font-medium',
    divAdditional: 'mt-[10px]',
    btnAddress: 'bg-grey px-[20px] py-[9.5px] mt-[10px] rounded-[10px] text-black text-[16px] font-regular'
}
import * as React from 'react';
import { typePrice } from '../../configs/functions';
import { Request } from '../../requests/request';
import { CheckBox } from '../CheckBox';
import { CustomSelect2 } from '../CustomSelect';
import { ModalAddress } from './ModalAddress';
import { ModalAddCategory } from './ModalAddCategory';
import { ModalAddSubCategory } from './ModalAddSubCategory';
import { ModalAddUser } from './ModalAddUser';
import { useDispatch } from 'react-redux';
import { setCategoriesRedux } from '../../redux/categories/categories';
import { setSubCategoriesRedux } from '../../redux/categories/subcategories';
import { setUsersRedux } from '../../redux/users/users';

export const ModalEditVacations = (props) => {
    const {data, onClose, onSuccess, token, categories, subcategories, users} = props;
    const dispatch = useDispatch();
    const [edit, setEdit] = React.useState({
        ...data,
        character: {value: data.character, label: data.character},
        typeWork: {value: data.typeWork, label: data.typeWork},
        typePrice: {value: data.typePrice, label: data.typePrice},
        category: {value: data.category, label: data.category, _id: ''},
        subcategory: {value: data.subcategory, label: data.subcategory},
        userType: {value: data.userType, label: data.userType}
    });
    const [chooseUser, setChooseUser] = React.useState({
        label: data.name + ' ' + data.surname,
        value: -1
    });
    const [modalAddress, setModalAddress] = React.useState(false);
    const [modalCategory, setModalCategory] = React.useState(false);
    const [modalAddUser, setModalAddUser] = React.useState(false);
    const [modalSubCategory, setModalSubCategory] = React.useState(false);

    React.useEffect(() => {
        if(chooseUser.value !== -1) {
            setEdit({
                ...edit,
                name: users[chooseUser.value].name,
                surname: users[chooseUser.value].surname,
                nameOrg:
                    users[chooseUser.value].typeUser === 'Performer'? 
                        users[chooseUser.value].subTypeUser_performer === 'Организация'?
                            users[chooseUser.value].short_name_org_performer:
                            '':
                        users[chooseUser.value].subTypeUser_client === 'Организация'?
                            users[chooseUser.value].short_name_org_client:
                            ''
                ,
                fcmToken: users[chooseUser.value].fcmToken,
                userType: users[chooseUser.value].userType === 'Performer'? 
                    users[chooseUser.value].subTypeUser_performer:
                    users[chooseUser.value].subTypeUser_client
                ,
                avatar: users[chooseUser.value].typeUser === 'Performer'? 
                    users[chooseUser.value].avatar_performer: 
                    users[chooseUser.value].avatar_client,
                idUser: users[chooseUser.value]._id
            });
        }
    }, [chooseUser.value]);

    React.useEffect(() => {
        if(edit.category.value === 'Новый') {
            setModalCategory(true);
            setEdit({
                ...edit,
                category: {label: '', value: ''}
            })
        }
        if(edit.subcategory.value === 'Новый') {
            setModalSubCategory(true);
            setEdit({
                ...edit,
                subcategory: {label: '', value: ''}
            })
        }
    }, [edit.category.value, edit.subcategory.value]);

    return(
        <div className={styles.main} >
            <div className={styles.div}>
                <p className={styles.username}>Изменить вакансию</p>
                <div className=''>
                    <p className={styles.label}>Категории:</p>
                    <div className='flex flex-row items-center justify-start mt-[10px]'>
                        <CustomSelect2 
                            data={[{label: 'Новый', value: 'Новый'}].concat(categories.map((item, index) => {return{value: item.name, label: item.name, _id: item._id}}))}
                            value={edit.category}
                            onChange={(value) => { setEdit({...edit, category: value, subcategory:{value: '', name: ''}}); }}
                            className1='w-1/4 mr-[10px]'
                        />
                        <CustomSelect2 
                            data={[{label: 'Новый', value: 'Новый'}].concat(subcategories.filter((item, index) => item.parent === edit.category._id).map((item, index) => {return{value: item.name, label: item.name}}))}
                            value={edit.subcategory}
                            onChange={(value) => { setEdit({...edit, subcategory: value}); }}
                            className1="w-1/4"
                        />
                    </div>
                    <p className={styles.label}>Вознаграждение:</p>
                    <div className='flex flex-row items-center justify-start mt-[10px]'>
                        <div className='flex flex-row items-center'>
                            <label className='text-center text-grey-text align-middle mr-[10px]'>От</label>
                            <input 
                                value={edit.price.minPrice}
                                onChange={(event) => { setEdit({...edit, price: {minPrice: parseInt(event.target.value), maxPrice: edit.price.maxPrice} }) }}
                                className={styles.price}
                                type={'number'}
                            />
                            <p className='mx-[10px]'> - </p>
                            <label className='text-center text-grey-text mr-[10px]'>До</label>
                            <input 
                                value={edit.price.maxPrice}
                                onChange={(event) => { setEdit({...edit, price: {maxPrice: parseInt(event.target.value), minPrice: edit.price.minPrice} }) }}
                                className={styles.price + ' mr-[10px]'}
                                type={'number'}
                            />
                        </div>
                        <div className=''>
                            <CustomSelect2
                                data={typePrice.map((item, index) => {return{value: item.name, label: item.name}})}
                                value={edit.typePrice}
                                onChange={(value) => { setEdit({...edit, typePrice: value}) }}
                                className1=''
                            />
                        </div>
                    </div>
                    <p className={styles.label}>Пользователь:</p>
                    <div className='flex flex-row mt-[10px]'>
                        <CustomSelect2 
                            data={users.map((item, index) => {
                                return{
                                    value: index, 
                                    label: 
                                        item.typeUser === 'Performer'?
                                            item.subTypeUser_performer === 'Организация'?
                                                item.surname + ' ' + item.name + ' ' + item.midname + '/' + item.short_name_org_performer:
                                                item.surname + ' ' + item.name + ' ' + item.midname:
                                            item.subTypeUser_client === 'Организация'?
                                                item.surname + ' ' + item.name + ' ' + item.midname + '/' + item.short_name_org_client:
                                                item.surname + ' ' + item.name + ' ' + item.midname
                                } 
                            })}
                            value={chooseUser}
                            onChange={(value) => { setChooseUser(value); }}
                            className1='w-1/2 mr-[20px]'
                        />
                        <CustomSelect2 
                            data={[{value: 'Физическое лицо', label: 'Физическое лицо'}, {label: 'Самозанятый', value: 'Самозанятый'}, {label: 'ИП', value: 'ИП'}, {label: 'Организация', value: 'Организация'}]}
                            value={edit.userType}
                            onChange={(value) => { setEdit({
                                ...edit,
                                userType: value
                            }) }}
                            className1='w-[200px]'
                        />
                    </div>
                    <button onClick={() => { setModalAddUser(true); console.log('s', modalAddUser); }} className='mt-[10px] h-[41px] w-[250px] bg-main rounded-[10px] text-white font-medium'>Новый пользователь</button>
                    <p className={styles.label}>Адрес:</p>
                    <button
                        className={styles.btnAddress}
                        onClick={() => {setModalAddress(true); }}
                    >{edit.address? edit.address: 'Выберите'}</button>
                    <p className={styles.label}>Характер работы:</p>
                    <div className='mt-[10px]'>
                        <CustomSelect2
                            data={[{value: 'Разовая', label: 'Разовая'}, {value: 'Постоянная', label: 'Постоянная'}]}
                            value={edit.character}
                            onChange={(value) => {
                                setEdit({...edit, typeWork: value});
                            }}
                            className1='w-1/4'
                        />
                    </div>
                    <p className={styles.label}>Тип работы:</p>
                    <div className='mt-[10px]'>
                        <CustomSelect2
                            data={[{value: 'На месте', label: 'На месте'}, {value: 'Удаленно', label: 'Удаленно'}]}
                            value={edit.typeWork}
                            onChange={(value) => {
                                setEdit({...edit, typeWork: value});
                            }}
                            className1='w-1/4'
                        />
                    </div>
                    <p className={styles.label}>Описание:</p>
                    <textarea
                        value={edit.description}
                        onChange={(event) => { setEdit({...edit, description: event.target.value}); }}
                        className={styles.descr}
                    />
                    <div className='mt-[10px]'>
                        <CheckBox 
                            value={edit.visible}
                            onChange={(value) => { setEdit({...edit, visible: value}) }}
                            text="Статус активности"
                        />
                    </div>
                    <div className='mt-[20px] mb-[20px]'>
                        <CheckBox 
                            value={edit.experience}
                            onChange={(value) => { setEdit({...edit, experience: value}); }}
                            text="Опыт работы требуется"
                        />
                        <CheckBox 
                            value={edit.vaccination}
                            onChange={(value) => { setEdit({...edit, vaccination: value}); }}
                            text="Вакцинация COVID-19"
                        />
                        <CheckBox 
                            value={edit.sanitary}
                            onChange={(value) => { setEdit({...edit, sanitary: value}); }}
                            text="Санкнижка"
                        />
                        <CheckBox 
                            value={edit.driverLicense}
                            onChange={(value) => { setEdit({...edit, driverLicense: value }); }}
                            text="Водительские права"
                        />
                    </div>       
                    <CheckBox 
                        value={edit.onlyOrganization}
                        onChange={(value) => {
                            setEdit({
                                ...edit,
                                onlyOrganization: value
                            })
                        }}
                        text="Только для самозанятых, ИП и организаций"
                    />
                    <CheckBox 
                        value={edit.onlyPhysical}
                        onChange={(value) => {
                            setEdit({
                                ...edit,
                                onlyPhysical: value
                            })
                        }}
                        text="Только для физ. лиц"
                    />
                </div>
                <div className={styles.divBtns}>
                    <button
                        className={styles.btnClose}
                        onClick={() => { onClose(); }}
                    >Закрыть</button>
                    <div>
                        <button
                            className={styles.btnDelete}
                            onClick={() => {
                                try {
                                    const response = Request(`/api/vacations/admin`, 'delete', token, JSON.stringify({ id: data._id }));
                                    response.then((json) => { if(json.status === 200) { onSuccess(); } })
                                } catch(e) { console.log('e', e); }
                            }}
                        >Удалить</button>
                        <button
                            className={styles.btnSave}
                            onClick={() => { 
                                try {
                                    console.log('edit', edit);
                                    const response = Request(`/api/vactions/admin`, 'put', token, JSON.stringify({
                                        data: { 
                                            id: edit._id, 
                                            ...edit, 
                                            typeWork: edit.typeWork.value,
                                            typePrice: edit.typePrice.value,
                                            category: edit.category.value,
                                            subcategory: edit.subcategory.value,
                                            character: edit.character.value,
                                            userType: edit.userType.value
                                        }
                                    }));
                                    response.then((json) => {
                                        if(json.status === 200) {
                                            onSuccess();
                                        }
                                    });
                                } catch(e) {
                                    console.log('e', e);
                                };
                            }}
                        >Сохранить</button>
                    </div>
                </div>
                {
                    modalAddress &&
                    <ModalAddress 
                        onChange={(value) => {
                            const point = value.GeoObject.Point.pos.split(' ');
                            setEdit({
                                ...edit,
                                address: value.GeoObject.description + ', ' + value.GeoObject.name,
                                placement: {
                                    latitude: parseFloat(point[1]),
                                    longitude: parseFloat(point[0]),
                                    address: value.GeoObject.description + ', ' + value.GeoObject.name
                                }
                            })
                            setModalAddress(false);
                            
                        }}
                        close={() => { setModalAddress(false); }}
                        value={{
                            address: edit.address,
                            placement: edit.placement
                        }}
                    />
                }
                {
                    modalCategory && <ModalAddCategory 
                        token={token}
                        close={() => { setModalCategory(false); }}
                        onSuccess={() => {
                            let tempData = Request(`/get/categories`, 'get', null, null);
                            tempData.then((json) => {
                                if(json.status === 200) {
                                    dispatch(setCategoriesRedux(json.data.data));
                                }
                                setModalCategory(false);
                            });
                        
                        }}
                    />
                }
                {
                    modalSubCategory && <ModalAddSubCategory 
                    token={token}
                    close={() => { setModalSubCategory(false); }}
                    onSuccess={() => {
                        let response = Request(`/get/subcategories`, 'get', null, null);
                            response.then((json) => {
                                if(json.status === 200 && json.data.status === 'success') {
                                    dispatch(setSubCategoriesRedux(json.data.data));
                                };
                                setModalSubCategory(false);
                            })
                    }}
                    />
                }
                {
                    modalAddUser && <ModalAddUser 
                        token={token}
                        setModalVisible={setModalAddUser}
                        onSuccess={() => {
                            const users = Request('/api/users', 'get', token, null);
                            users.then((json) => {
                                if(json.status === 200) {
                                    dispatch(setUsersRedux(json.data.data));
                                };
                            })
                            setModalAddUser(false);
                        }}
                    />
                }
            </div>
        </div>
    );
};

const styles = {
    main: 'absolute modal top-0 left-0 flex w-full h-full items-center justify-center',
    div: 'bg-white shadow-[0_2px_20px_0px_rgba(0,0,0,0.12)] p-[20px] rounded-[12px] w-3/4 overflow-y-scroll h-3/4',
    label: 'text-grey-text font-regular mt-[20px]',
    name: 'outline-none border-main border-[1px] py-[10px] px-[20px] rounded-[12px] mt-[10px] w-1/2',
    typeWork: 'mt-[10px] border-main',
    price: 'border-main border-[1px] py-[10px] px-[20px] rounded-[12px] outline-main w-[150px]',
    username: 'font-medium text-[20px] text-black',
    descr: 'border-main border-[1px] rounded-[12px] px-[20px] py-[10px] mt-[10px] w-3/4 outline-none',
    divBtns: 'flex flex-row items-center justify-between mt-[20px]',
    btnClose: 'px-[20px] py-[10px] rounded-[10px] bg-grey font-medium text-[16px]',
    btnSave: 'px-[20px] py-[10px] rounded-[10px] bg-main text-white font-medium text-[16px]',
    btnDelete: 'px-[20px] py-[10px] mr-[20px] text-red font-medium text-[16px]',
    btnAddress: 'px-[20px] bg-grey py-[9.5px] mt-[10px] rounded-[10px] text-black text-[16px] font-regular'
};